import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalService} from '@azure/msal-angular';
import { InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserIdleModule } from 'angular-user-idle';
import { environment } from '../../environments/environment';
import { B2CPolicyFlows } from '../core/constants';
import { MsalEffects } from './effects/msal.effects';
import { TimeoutEffects } from './effects/timeout.effects';
import * as fromMsal from './reducers/msal.reducers';
import { DEADMsalService } from './services/dead-msal.service';
import { TimeoutModalComponent } from './timeout-modal/timeout-modal.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    switch (logLevel) {
        case LogLevel.Error:
            console.error(message);
            break;
        case LogLevel.Info || LogLevel.Verbose:
            console.info(message);
            break;
        case LogLevel.Warning:
            console.warn(message);
            break;
        default:
            break;
    }
}

@NgModule({
    declarations: [TimeoutModalComponent],
    imports: [
        CommonModule,
        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
            auth: {
                clientId: B2CPolicyFlows.B2C_CLIENT_ID,
                authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNIN_FLOW}`,
                knownAuthorities: [
                    `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNIN_FLOW}`,
                    `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNUP_FLOW}`,
                    `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_PASSWORD_RESET_FLOW}`,
                    `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_PROFILE_EDIT_FLOW}`
                ],
                redirectUri: `${environment.baseUrl}/login-redirect`,
                postLogoutRedirectUri: `${environment.baseUrl}/logout-redirect`,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: isIE, // set to true for IE 11
            },
            // uncomment to see more logging info
            // system: {
            //     loggerOptions: {
            //         loggerCallback,
            //         piiLoggingEnabled: true
            //     }
            // }
        }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: [...B2CPolicyFlows.B2C_SCOPES],
                }, // MSAL Guard Configuration
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                    [environment.ADMIN_API_BASE, B2CPolicyFlows.B2C_SCOPES],
                    [environment.STUDENT_API_BASE, B2CPolicyFlows.B2C_SCOPES],
                    [environment.INSTRUCTOR_API_BASE, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}subscriptions/purchase`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}vouchers/purchase`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}faq/list/true`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}subscriptions/voucher-code/apply`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}users/updateDetails`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}users/graduationDate/update`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}school/get`, B2CPolicyFlows.B2C_SCOPES],
                    [`${environment.GENERAL_API_BASE}school/getAll`, B2CPolicyFlows.B2C_SCOPES]
                ])
            }
        ),
        StoreModule.forFeature(fromMsal.msalFeatureKey, fromMsal.reducer, { metaReducers: fromMsal.metaReducers }),
        EffectsModule.forFeature([MsalEffects, TimeoutEffects]),
        // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
        // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
        // and `ping` is 120 (2 minutes).
        UserIdleModule.forRoot({ idle: 600, timeout: 30, ping: 120 }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        DEADMsalService,
    ]
})
export class DEADMsalModule { }
