import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    addEditDeleteUserComplete,
    addEditDeleteUserFailure,
    addUser,
    deleteUser,
    editUser,
    exportUsers,
    exportUsersFinished,
    loadCurrentUser,
    loadCurrentUserFailure,
    loadCurrentUserSuccess,
    loadUsers,
    loadUsersFailure,
    loadUsersSuccess,
    loadUsersWithFilter,
    reloadUsers,
    updateCurrUserComplete,
    updateCurrUserDetails,
    updateCurrUserGradDate,
    updateUserDetails,
    updateUserGradDate,
    updateUserGroups,
    updateUserSchool,
    updateUserSubscription
} from '../actions/user.actions';
import { ToastService } from '../core/services/toast.service';
import { UserService } from '../core/services/user.service';
import { loginResponseRecieved, setUserState } from '../msal/actions/msal-login.actions';
import { DEADMsalService } from '../msal/services/dead-msal.service';
import * as moment from "moment";



@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private toastService: ToastService,
        private router: Router,
        private deadMsalService: DEADMsalService,
    ) { }

    loadCurrentUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadCurrentUser),
                exhaustMap(() =>
                    this.userService.getCurrent()
                        .pipe(
                            map((user) => {
                                return loadCurrentUserSuccess({ user });
                            }),
                            catchError((error) => {
                                return of(loadCurrentUserFailure({ error }));
                            })
                        )
                )
            )
    );

    loadUsers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadUsers),
                exhaustMap(() =>
                    this.userService.getAll()
                        .pipe(
                            map((users) => {
                                return loadUsersSuccess({ users });
                            }),
                            catchError((error) => {
                                return of(loadUsersFailure({ error }));
                            })
                        )
                )
            )
    );

    loadUsersWithFilter$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadUsersWithFilter),
                exhaustMap(({ filter, fromDate, toDate }) =>
                    this.userService.getAll()
                        .pipe(
                            map((users) => {
                                let usersFilter = users.filter((p) => {
                                    let bool = (
                                        p.firstName.toLowerCase().includes(filter.toLowerCase()) ||
                                        p.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                                        p.email?.toLowerCase().includes(filter.toLowerCase())
                                    );

                                    if (fromDate) {
                                       bool = bool && moment(p.graduationDate) >= moment(fromDate)
                                    }

                                    if (toDate) {
                                        bool = bool && moment(p.graduationDate) <= moment(toDate)
                                    }

                                    return bool;
                                });
                                return loadUsersSuccess({ users: usersFilter });
                            }),
                            catchError((error) => {
                                return of(loadUsersFailure({ error }));
                            })
                        )
                )
            )
    );

    reloadUsers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(reloadUsers),
                exhaustMap(() =>
                    this.userService.clearUserCache()
                        .pipe(
                            map(() => {
                                return loadUsers();
                            }),
                            catchError((error) => {
                                return of(loadUsersFailure({ error }));
                            })
                        )
                )
            )
    );

    addUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addUser)
            )
    );

    editUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editUser)
            )
    );

    updateUserGroups$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUserGroups),
                exhaustMap(data =>
                    this.userService.updateUserGroups(data.user)
                        .pipe(
                            map(() => {
                                return addEditDeleteUserComplete({ user: data.user, redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Group', 'User Group Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateUserSubscription$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUserSubscription),
                exhaustMap(data =>
                    this.userService.updateUserSubEndDate(data.user)
                        .pipe(
                            map(() => {
                                return addEditDeleteUserComplete({ user: data.user, redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Subscription', 'User Subscription Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateUserDetails$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUserDetails),
                exhaustMap(data =>
                    this.userService.updateUserDetails(data.user)
                        .pipe(
                            map(() => {
                                return addEditDeleteUserComplete({ user: data.user, redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Details', 'User Details Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateCurrUserDetails$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateCurrUserDetails),
                exhaustMap(data =>
                    this.userService.updateUserDetails(data.user)
                        .pipe(
                            mergeMap(() => this.deadMsalService.acquireTokenSilent()),
                            mergeMap(authResult => {
                                return [
                                    updateCurrUserComplete({ user: data.user }),
                                    setUserState({
                                        newState: {
                                            isLoggedIn: this.deadMsalService.isLoggedIn(),
                                            isAdmin: this.deadMsalService.isAdmin(),
                                            isInstructor: this.deadMsalService.isInstructor(),
                                            isStudent: this.deadMsalService.isStudent(),
                                            schoolId: this.deadMsalService.getSchoolId(),
                                            subExirationDate: this.deadMsalService.getSubExirationDate(),
                                            graduationDate: this.deadMsalService.getGrauationdDate(),
                                        }
                                    })
                                ];
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Details', 'User Details Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateCurrUserGradDate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateCurrUserGradDate),
                exhaustMap(data =>
                    this.userService.updateUserGradDate(data.user)
                        .pipe(
                            mergeMap(() => this.deadMsalService.acquireTokenSilent()),
                            mergeMap(authResult => {
                                return [
                                    updateCurrUserComplete({ user: data.user }),
                                    setUserState({
                                        newState: {
                                            isLoggedIn: this.deadMsalService.isLoggedIn(),
                                            isAdmin: this.deadMsalService.isAdmin(),
                                            isInstructor: this.deadMsalService.isInstructor(),
                                            isStudent: this.deadMsalService.isStudent(),
                                            schoolId: this.deadMsalService.getSchoolId(),
                                            subExirationDate: this.deadMsalService.getSubExirationDate(),
                                            graduationDate: this.deadMsalService.getGrauationdDate(),
                                        }
                                    })
                                ];
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Details', 'User Details Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateUserGradDate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUserGradDate),
                exhaustMap(data =>
                    this.userService.updateUserGradDate(data.user)
                        .pipe(
                            map(() => {
                                return addEditDeleteUserComplete({ user: data.user, redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating User Expected Grad Date', 'User Expected Grad Date Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    updateUserSchool$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateUserSchool),
                exhaustMap(data =>
                    this.userService.updateUserSchool(data.user)
                        .pipe(
                            map(() => {
                                return addEditDeleteUserComplete({ user: data.user, redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Updating Users School', 'User School Update Failed!');
                                return of(addEditDeleteUserFailure({ error }));
                            })
                        )
                )
            )
    );

    deleteUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteUser)
            )
    );

    addEditDeleteUserComplete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addEditDeleteUserComplete),
                exhaustMap(action => {
                    if (action.redirectUrl) {
                        this.router.navigate([action.redirectUrl]);
                    }

                    return of(loadUsers());
                })
            )
    );
    exportUsers$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(exportUsers),
                exhaustMap(action =>
                    this.userService.exportUsers()
                    .pipe(
                        map(() => {
                            return exportUsersFinished();
                        }),
                        catchError(() => {
                            this.toastService.error('Unable to export users.');
                            return of(exportUsersFinished());
                        })
                    )
                )
            )
    );
}
