import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  inject,
} from "@angular/core";
import {
  faBars,
  faSignInAlt,
  faEnvelope,
  faNewspaper,
  faQuestionCircle,
  faBuilding,
  faSchool,
  faChalkboardTeacher,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Title } from "@angular/platform-browser";
import { Subscription, throwError } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromRoot from "./reducers/app.reducers";
import { selectUrl } from "./reducers/routing.reducers";
import { Spinkit } from "ng-http-loader";
import {
  getCurrentUserFromState,
  UserState,
} from "src/app/reducers/user.reducers";
import { catchError, filter, first } from "rxjs/operators";
import { AppToasterService, GameSocketService } from "./game/utils/services";
import { DEADMsalService } from "./msal/services/dead-msal.service";
import { User } from "./core/models/user.model";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";

@Component({
  selector: "dead-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly _router = inject(Router);
  private readonly _userStore = inject(Store<UserState>);
  private readonly _gameSocket = inject(GameSocketService);
  private readonly _deadMsalService = inject(DEADMsalService);
  private readonly _toasterSvc = inject(AppToasterService);
  title = "D.E.A.D.";
  pageNameBase = "D.E.A.D.";

  height: number = document.documentElement.clientHeight - 45;
  state = {
    open: false,
  };
  faBars = faBars;
  faSignInAlt = faSignInAlt;
  faEnvelope = faEnvelope;
  faNewspaper = faNewspaper;
  faQuestionCircle = faQuestionCircle;
  faBuilding = faBuilding;
  faSchool = faSchool;
  faChalkboardTeacher = faChalkboardTeacher;
  faHome = faHome;

  showLoading = false;
  routerEventsSubscription: Subscription;
  isGameRoute = true;
  showHeaderFooter = false;
  isIframe = false;
  spinkit = Spinkit;

  inviteFromUser: Record<string, number> = {};

  private readonly subscriptions = new Subscription();

  hideInvitation = false;

  constructor(
    private titleService: Title,
    private appStore: Store<fromRoot.State>
  ) {
    this.titleService.setTitle(this.pageNameBase);
  }

  ngAfterViewInit(): void {
    // initialization of go to
    jQuery["HSCore"].components.HSGoTo.init(".js-go-to");
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.showHeaderFooter = true;

    this.subscriptions.add(
      this.appStore.select(selectUrl).subscribe((url) => {
        switch (url) {
          case "/redirect":
          case "/coming-soon":
            this.showHeaderFooter = false;
            break;
          default:
            this.showHeaderFooter = true;
            this.isGameRoute = false;
        }
        if (url?.startsWith("/game")) {
          this.isGameRoute = true;
          this.showHeaderFooter = true;
        }
        this.hideInvitation =
          url?.startsWith("/game") &&
          (url?.endsWith("/topic") ||
            url?.endsWith("/waiting-opponents") ||
            url?.endsWith("/question/") ||
            url?.endsWith("/selected-topics"));
      })
    );

    this._setCurrentUser();
  }

  private _setCurrentUser(): void {
    this._userStore
      .select(getCurrentUserFromState)
      .pipe(filter((user) => user !== null))
      .subscribe((user) => {
        if (!user.groups.includes("Student")) return;
        if (this._gameSocket.isInitiated()) return;
        this._initGameEvents(user);
      });
  }

  private _initGameEvents(user: User): void {
    this._deadMsalService
      .acquireTokenSilent()
      // .pipe(filter((authResult: AuthenticationResult) => authResult !== null))
      .subscribe((msalRes) => {
        this._gameSocket.init(user, msalRes.accessToken);
        this._gameSocket.onGameInvitation().subscribe((res) => {
          if (this.hideInvitation) return;
          if (this.inviteFromUser[res.game.userId]) {
            this._toasterSvc.removeById(this.inviteFromUser[res.game.userId]);
            delete this.inviteFromUser[res.game.userId];
          }
          this._toasterSvc.playSound();
          const toast = this._toasterSvc.confirm(
            `<b>${res.game.user.name}</b> has invite you to join the game. Do you want to join?`,
            ""
          );
          this.inviteFromUser[res.game.userId] = toast.toastId;
          toast.onAction.subscribe((accept) => {
            const invite = res.game.invitation.find(
              (item) => item.userId === user.userId
            );
            if (!invite) return;
            this._updateStatus(
              res.game.id,
              invite.id,
              accept === "accept" ? "ACCEPTED" : "REJECTED"
            );
            if (accept === "accept") {
              this._toasterSvc.clearAll();
              this.inviteFromUser = {};
            }
          });
        });

        this._gameSocket.invitationStatusChange().subscribe((res) => {
          this._toasterSvc.playSound();
          if (res.status === "ACCEPTED") {
            this._toasterSvc.success(
              `<b>${res.user.name}</b> has joined the game`,
              ""
            );
          }
        });
      });
  }

  private _updateStatus(
    gameId: string,
    inviteId: string,
    status: string
  ): void {
    this._gameSocket
      .updateInviteStatus(gameId, inviteId, status)
      .pipe(
        first(),
        catchError((e) => {
          console.log("============e", e);
          this._toasterSvc.error(e.message, "");
          return throwError(e);
        })
      )
      .subscribe((res) => {
        if (status === "ACCEPTED") {
          void this._router.navigate(["game", gameId, "topic"]);
        }
      });
  }

  onMenuChange() {
    this.state.open = !this.state.open;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
