import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbNavModule, NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TabsModule } from "ngx-bootstrap/tabs";
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideEnvironmentNgxMask,
} from "ngx-mask";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AccountComponent } from "./account/account.component";
import { initializeAppStart } from "./actions/app.actions";
import { AdminComponent } from "./admin/admin.component";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ApiSubscriptionInterceptor } from "./core/interceptors/api-subscription.interceptor";
import { PipesModule } from "./core/pipes/pipes.module";
import { FrequentlyAskedQuestionsComponent } from "./frequently-asked-questions/frequently-asked-questions.component";
import { HomeComponent } from "./home/home.component";
import { LoginRedirectComponent } from "./login-redirect/login-redirect.component";
import { LogoutRedirectComponent } from "./logout-redirect/logout-redirect.component";
import { DEADMsalModule } from "./msal/msal.module";
import { NbeReviewManualComponent } from "./nbe-review-manual/nbe-review-manual.component";
import { NbeReviewWebinarSignupComponent } from "./nbe-review-webinar-signup/nbe-review-webinar-signup.component";
import { NbeTutoringSessionsArtsComponent } from "./nbe-tutoring-sessions-signup/nbe-tutoring-sessions-arts/nbe-tutoring-sessions-arts.component";
import { NbeTutoringSessionsScienceComponent } from "./nbe-tutoring-sessions-signup/nbe-tutoring-sessions-science/nbe-tutoring-sessions-science.component";
import { NbeTutoringSessionsSignupComponent } from "./nbe-tutoring-sessions-signup/nbe-tutoring-sessions-signup.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PurchaseConfirmationComponent } from "./purchase-confirmation/purchase-confirmation.component";
import * as fromRoot from "./reducers/app.reducers";
import {
  metaReducers,
  ROOT_EFFECTS,
  ROOT_REDUCERS,
} from "./reducers/app.reducers";
import { SessionTimeoutComponent } from "./session-timeout/session-timeout.component";
import { SharedModule } from "./shared/shared.module";
import { SignInSignUpComponent } from "./sign-in-sign-up/sign-in-sign-up.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { TermsComponent } from "./terms/terms.component";
import { FulfillmentComponent } from "./fulfillment/fulfillment.component";
import { NgHttpLoaderModule } from "ng-http-loader";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    ComingSoonComponent,
    SignInSignUpComponent,
    AccountComponent,
    SessionTimeoutComponent,
    PageNotFoundComponent,
    AdminComponent,
    PurchaseConfirmationComponent,
    LoginRedirectComponent,
    LogoutRedirectComponent,
    NbeReviewManualComponent,
    NbeTutoringSessionsSignupComponent,
    NbeTutoringSessionsArtsComponent,
    NbeTutoringSessionsScienceComponent,
    NbeReviewWebinarSignupComponent,
    FrequentlyAskedQuestionsComponent,
    PrivacyComponent,
    TermsComponent,
    FulfillmentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgSelectModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      enableHtml: true,
    }),
    NgxDatatableModule.forRoot({
      messages: {
        emptyMessage: "No data to display", // Message to show when array is presented, but contains no values
        totalMessage: "total", // Footer total message
        selectedMessage: "selected", // Footer selected message
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production || environment.pwaLocal,
      registrationStrategy: "registerImmediately",
    }),
    NgxMaskDirective,
    NgxMaskPipe,
    NgMultiSelectDropDownModule.forRoot(),
    PipesModule,
    SharedModule,
    DEADMsalModule,
    StoreModule.forRoot(ROOT_REDUCERS, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(ROOT_EFFECTS),
    !environment.production
      ? StoreDevtoolsModule.instrument()
      : StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
        }),
    NgHttpLoaderModule.forRoot(),
    NgbNavModule,
    NgbProgressbarModule,
    BsDatepickerModule.forRoot(),
    TabsModule,
    AccordionModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<fromRoot.State>) => {
        return () => {
          store.dispatch(initializeAppStart());
        };
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiSubscriptionInterceptor,
      multi: true,
    },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
