import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {


    constructor(private toastrService: ToastrService) {}

    error(body: string, title: string = 'Error') {
        const options = {}
        this.toastrService.error(body, title);
    }
    success(body: string, title: string = 'Success') {
        this.toastrService.success(body, title);
    }
}
