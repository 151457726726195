import { createAction, props } from '@ngrx/store';
import { VoucherCode } from 'src/app/core/models/voucher-code.model';
import { PurchaseSubscription } from 'src/app/shared/purchaseSubscription';
import {PurchaseVouchers} from "../../shared/purchaseVouchers";

export const startPurchaseSubscription = createAction(
    '[Subscription] Start Purchase Subscription',
    props<{ purchaseSubscription : PurchaseSubscription , stopRedirect?: boolean }>()
);

export const startPurchaseVouchers = createAction(
    '[Subscription] Start vouchers Subscription',
    props<{ purchaseVouchers: PurchaseVouchers , stopRedirect?: boolean }>()
);

export const createStripeTokenFailure = createAction(
    '[Subscription] Create Stripe Token Failure',
    props<{ error: any }>()
);

export const purchaseSubscriptionSuccess = createAction(
    '[Subscription] Create Stripe Token Success',
    props<{ subscriptionCode: string, sendCheck: boolean, subscriptionEndDate: Date | undefined, stopRedirect?: boolean, purchaseSuccess: boolean }>()
);
export const purchaseVoucherSuccess = createAction(
    '[Subscription] Create Voucher Success',
    props<{ subscriptionCode: string, sendCheck: boolean, subscriptionEndDate: Date | undefined, stopRedirect?: boolean, purchaseSuccess: boolean }>()
);

export const purchaseVouchersFailure = createAction(
    '[Subscription] Voucher Failure',
    props<{ error: any }>()
);

export const resetPurchaseState = createAction(
    '[Subscription] Reset Purchase state'
);

export const purchaseSubscriptionFailure = createAction(
    '[Subscription] Create Stripe Token Failure',
    props<{ error: any }>()
);


export const applyVoucherCodeStart = createAction(
    '[Subscription] Apply Voucher Code Start',
    props<{ voucherCode: string, subscriptionCode: string | undefined }>()
);

export const updatePurchaseHistory = createAction(
    '[Subscription] Update',
    props<{ id: string, userEmail: string, sendEmail: boolean }>()
);

export const applyVoucherCodeSuccess = createAction(
    '[Subscription] Apply Voucher Code Success',
    props<{ voucherCodeVM: VoucherCode }>()
);

export const applyVoucherCodeFailure = createAction(
    '[Subscription] Apply Voucher Code Failure',
    props<{ error: string }>()
);

export const clearSubscriptionState = createAction(
    '[Subscription] Clear Subscription State'
);

