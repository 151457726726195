import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserIdleService } from 'angular-user-idle';
import { EMPTY, from, of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { logoutStarted } from '../actions/msal-logout.actions';
import { timeoutOnTimeout, timeoutStartTimer, timeoutStartWatching, timeoutStopTimer } from '../actions/timeout.actions';
import { TimeoutModalComponent } from '../timeout-modal/timeout-modal.component';



@Injectable()
export class TimeoutEffects {

    constructor(
        private actions$: Actions,
        private userIdle: UserIdleService,
        private modalService: NgbModal,
    ) { }

    timeoutWatchStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(timeoutStartWatching),
                exhaustMap(() => {
                    //Start watching for user inactivity.
                    this.userIdle.startWatching();
                    return of(timeoutStartTimer(), timeoutOnTimeout());
                })
            )
    );

    timeoutTimerStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(timeoutStartTimer),
                exhaustMap(() => {
                    let dialogRef: NgbModalRef = null;
                    // Start watching when user idle is starting.
                    return this.userIdle.onTimerStart()
                        .pipe(
                            map(count => {
                                if (count === 1) {
                                    dialogRef = this.modalService.open(TimeoutModalComponent);
                                }
                                dialogRef.componentInstance.count = count;
                                from(dialogRef.result).subscribe(
                                    () => null,
                                    () => null,
                                );
                            })
                        );
                })
            ),
        { dispatch: false }
    );

    timeoutTimerStopped$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(timeoutStopTimer),
                exhaustMap(() => {
                    this.userIdle.resetTimer();
                    return EMPTY;
                })
            ),
        { dispatch: false }
    );

    timeoutTriggered$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(timeoutOnTimeout),
                exhaustMap(() => {
                    // Start watch when time is up.
                    return this.userIdle.onTimeout()
                        .pipe(map(() => logoutStarted({ redirectUrl: '' })));
                })
            )
    );
}
