import { createAction, props } from '@ngrx/store';
import { User } from '../core/models/user.model';

export const loadCurrentUser = createAction('[User] Load Current User');

export const loadCurrentUserSuccess = createAction(
    '[User] Load Current User Success',
    props<{ user: User }>()
);

export const loadCurrentUserFailure = createAction(
    '[User] Load Current User Failure',
    props<{ error: any }>()
);

export const loadUsers = createAction('[User] Load Users');

export const reloadUsers = createAction('[User] Reload Users');

export const loadUsersWithFilter = createAction(
    '[User] Load Users With Filter',
    props<{ filter: string, fromDate?: Date, toDate?: Date }>()
);

export const loadUsersSuccess = createAction(
    '[User] Load Users Success',
    props<{ users: User[] }>()
);

export const loadUsersFailure = createAction(
    '[User] Load Users Failure',
    props<{ error: any }>()
);

export const addUser = createAction(
    '[User] Add User',
    props<{ user: User, redirectUrl: string }>()
);

export const editUser = createAction(
    '[User] Edit User',
    props<{ user: User, redirectUrl: string }>()
);

export const deleteUser = createAction(
    '[User] Delete User',
    props<{ userId: string }>()
);

export const updateUserDetails = createAction(
    '[User] Update User Details',
    props<{ user: User, redirectUrl: string }>()
);

export const updateCurrUserDetails = createAction(
    '[User] Update Current User Details',
    props<{ user: User }>()
);

export const updateUserGroups = createAction(
    '[User] Update User Groups',
    props<{ user: User, redirectUrl: string }>()
);

export const updateUserSubscription = createAction(
    '[User] Update User Subscription',
    props<{ user: User, redirectUrl: string }>()
);

export const updateUserGradDate = createAction(
    '[User] Update User Grad Date',
    props<{ user: User, redirectUrl: string }>()
);

export const updateCurrUserGradDate = createAction(
    '[User] Update Current User Expected Grad Date',
    props<{ user: User }>()
);

export const updateUserSchool = createAction(
    '[User] Update User School Selection',
    props<{ user: User, redirectUrl: string }>()
);

export const addEditDeleteUserComplete = createAction(
    '[User] Add/Edit/Delete User Complete',
    props<{ user: User, redirectUrl: string }>()
);

export const addEditDeleteUserFailure = createAction(
    '[User] Add/Edit/Delete User Failure',
    props<{ error: any }>()
);

export const updateCurrUserComplete = createAction(
    '[User] Update Current User Complete',
    props<{ user: User }>()
);

export const updateCurrUserFailure = createAction(
    '[User] Update Current User Failure',
    props<{ error: any }>()
);
export const exportUsers = createAction(
    '[User] Export Users'
);
export const exportUsersFinished = createAction(
    '[User] Export Users Finished'
);
