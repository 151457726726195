import { Component } from '@angular/core';

@Component({
  selector: 'dead-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.scss']
})
export class FulfillmentComponent {

  constructor() { }

}
