import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { delay, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DbService } from '../../core/services/db.service';
import { editUserStarted } from '../actions/msal-edit-user.actions';
import {
    loginFailure,
    loginFailureComplete,
    loginResponseRecieved,
    loginStarted,
    loginSuccess,
    setUserState
} from '../actions/msal-login.actions';
import { logoutStarted } from '../actions/msal-logout.actions';
import { passwordResetStarted, passwordResetSuccess } from '../actions/msal-password-reset.actions';
import { signupStarted } from '../actions/msal-signup.actions';
import { timeoutStartWatching } from '../actions/timeout.actions';
import { DEADMsalService } from '../services/dead-msal.service';



@Injectable()
export class MsalEffects {

    constructor(
        private actions$: Actions,
        private deadMsalService: DEADMsalService,
        private dbService: DbService,
        private router: Router,
        private toastService: ToastrService,
        private zone: NgZone
    ) { }

    loginStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loginStarted),
                map(action => action.redirectUrl),
                exhaustMap(redirectUrl => this.deadMsalService.login(redirectUrl))
            ),
        { dispatch: false }
    );

    loginResponseRecieved$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loginResponseRecieved),
                map(actions => actions.resp),
                exhaustMap(resp => {
                    return this.dbService.deleteAllData()
                        .pipe(
                            mergeMap(() => {
                                return [
                                    setUserState({
                                        newState: {
                                            isLoggedIn: this.deadMsalService.isLoggedIn(),
                                            isAdmin: this.deadMsalService.isAdmin(),
                                            isInstructor: this.deadMsalService.isInstructor(),
                                            isStudent: this.deadMsalService.isStudent(),
                                            schoolId: this.deadMsalService.getSchoolId(),
                                            subExirationDate: this.deadMsalService.getSubExirationDate(),
                                            graduationDate: this.deadMsalService.getGrauationdDate(),
                                        }
                                    }),
                                    loginSuccess({ resp }),
                                    timeoutStartWatching()
                                ];
                            })
                        );
                })
            )
    );

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loginSuccess),
                map(actions => actions.resp),
                tap(resp => {
                    if (resp.state.toLowerCase().indexOf('home') > 0) {
                        this.zone.run(() => this.router.navigate(['game']));
                        return;
                        if (this.deadMsalService.isAdmin()) {
                            this.zone.run(() => this.router.navigate(['admin']));
                        }
                        else if (this.deadMsalService.isStudent()) {
                            this.zone.run(() => this.router.navigate(['student']));
                        }
                        else if (this.deadMsalService.isInstructor()) {
                            this.zone.run(() => this.router.navigate(['instructor', resp.idTokenClaims['extension_SchoolId']]));
                            ;
                        }
                        else {
                            this.zone.run(() => this.router.navigate(['home']));
                        }
                    } else {
                        this.zone.run(() => this.router.navigateByUrl(resp.state));
                    }
                })
            ),
        { dispatch: false }
    );

    loginFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loginFailure),
                map(actions => actions.error),
                exhaustMap(err => {
                    if (err.errorMessage.includes('AADB2C90091')) {
                        this.router.navigate(['home']);
                    } else if (err.errorMessage.includes('AADB2C90118')) {
                        return of(passwordResetStarted({ redirectUrl: '' }));
                    }
                    else {
                        console.error(err.errorMessage, err);
                    }
                    return of(loginFailureComplete());
                })
            )
    );

    passwordResetStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(passwordResetStarted),
                map(action => action.redirectUrl),
                exhaustMap(redirectUrl => this.deadMsalService.passwordReset(redirectUrl))
            ),
        { dispatch: false }
    );

    passwordResetSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(passwordResetSuccess),
                switchMap(data => {
                    this.toastService.success('Please sign-in with your new password.', 'Password has been reset successfully!');
                    return of(loginStarted({ redirectUrl: '' })).pipe(delay(4000));
                })
            )
    );

    signupStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(signupStarted),
                map(action => action.redirectUrl),
                exhaustMap(redirectUrl => this.deadMsalService.goToSignup(redirectUrl))
            ),
        { dispatch: false }
    );

    editUserStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editUserStarted),
                map(action => action.redirectUrl),
                exhaustMap(redirectUrl => this.deadMsalService.editUser(redirectUrl))
            ),
        { dispatch: false }
    );

    logoutStarted$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logoutStarted),
                map(action => action.redirectUrl),
                exhaustMap(redirectUrl => this.deadMsalService.logout(redirectUrl))
            ),
        {
            dispatch: false
        }
    );

}
