import {
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { loginFailure, setUserState } from '../actions/msal-login.actions';
import { logoutFailure, logoutSuccess } from '../actions/msal-logout.actions';

export const msalFeatureKey = 'msal';

export interface MsalState {
    isLoggedIn: boolean;
    isAdmin: boolean;
    isStudent: boolean;
    isInstructor: boolean;
    schoolId: string;
    subExirationDate: Date;
    graduationDate: Date;
}

const initialState: MsalState = {
    isLoggedIn: false,
    isAdmin: false,
    isStudent: false,
    isInstructor: false,
    schoolId: null,
    subExirationDate: null,
    graduationDate: null,
}

export const reducer = createReducer(
    initialState,
     on(setUserState, (state, { newState }) => ({
        ...state,
        isLoggedIn: newState.isLoggedIn,
        isAdmin: newState.isAdmin,
        isStudent: newState.isStudent,
        isInstructor: newState.isInstructor,
        schoolId: newState.schoolId,
        subExirationDate: newState.subExirationDate,
        graduationDate: newState.graduationDate,
    })),
    on(loginFailure, (state, { error }) => ({ ...state, isLoggedIn: false, error: error })),
    on(logoutSuccess, (state) => ({ ...state, initialState })),
    on(logoutFailure, (state, { error }) => ({ ...state, isLoggedIn: false, error: error })),
);

export const metaReducers: MetaReducer<MsalState>[] = !environment.production ? [] : [];

export const getMsalState = createFeatureSelector<MsalState>(msalFeatureKey);

export const getLoginState = createSelector(getMsalState, (state): MsalState => state);
