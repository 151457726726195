import {
  Component,
  EventEmitter,
  inject,
  Inject,
  Injectable,
  Output,
} from "@angular/core";
import { TOAST_CONFIG, ToastPackage, ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

type Confirmation = "accept" | "reject";

@Component({
  selector: "app-custom-toast",
  template: `<div
    style="min-width: 350px"
    class="ng-trigger-flyInOut custom-toaster-item-success toast-success"
  >
    <div
      class="ng-tns-c65-0 toast-title ng-star-inserted"
      aria-label="Custom Toast"
      style=""
      [innerHTML]="title"
    ></div>
    <div
      role="alert"
      class="ng-tns-c65-0 custom-toaster-item-message ng-star-inserted"
      aria-label="Custom message"
      style=""
      [innerHTML]="message"
    ></div>
    <button (click)="onAccept()" class="action-accept">Accept</button>
    <button (click)="onReject()" class="action-reject">Reject</button>
  </div>`,
  standalone: true,
})
class CustomToastComponent {
  message = "";
  title = "";

  constructor(
    @Inject(TOAST_CONFIG) private toastConfig: any,
    private toastPackage: ToastPackage,
    private toastrService: ToastrService
  ) {
    this.message = this.toastPackage.message;
    this.title = this.toastPackage.title;
  }

  onAccept() {
    this.toastPackage.triggerAction("accept");
    this.toastrService.remove(this.toastPackage.toastId);
  }

  onReject() {
    this.toastPackage.triggerAction("reject");
    this.toastrService.remove(this.toastPackage.toastId);
  }
}

interface ConfirmToast {
  toastId: number;
  onAction: Observable<Confirmation>;
}

@Injectable({
  providedIn: "root",
})
export class AppToasterService {
  private readonly _toasterSvc = inject(ToastrService);
  private _audio = new Audio("assets/notification.wav");

  playSound(): void {
    this._audio.load();
    this._audio.play();
  }

  success(message: string, title: string): void {
    this._toasterSvc.success(message, title, {
      messageClass: "custom-toaster-item-message",
      toastClass: "custom-toaster-item-success",
      closeButton: true,
    });
  }

  error(message: string, title: string): void {
    this._toasterSvc.success(message, title, {
      messageClass: "custom-toaster-item-message",
      toastClass: "custom-toaster-item-error",
      closeButton: true,
    });
  }

  confirm(message: string, title: string): ConfirmToast {
    const toast = this._toasterSvc.show(message, title, {
      messageClass: "custom-toaster-item-message",
      toastClass: "custom-toaster-item-success",
      toastComponent: CustomToastComponent,
      timeOut: 0,
      tapToDismiss: false,
    });
    console.log("==========", { toast });
    return { toastId: toast.toastId, onAction: toast.onAction };
  }

  removeById(id: number): void {
    this._toasterSvc.remove(id);
  }

  clearAll(): void {
    this._toasterSvc.clear();
  }
}
