<!-- ========== HEADER ========== -->
<header id="header"
    class="position-relative u-header u-header--modern u-header--bordered u-header--sticky-top-lg u-header--show-hide-lg u-header--toggle-section-lg"
    data-header-fix-moment="500"
    data-header-fix-effect="slide">
    <div class="u-header__section">
        <div id="logoAndNav"
            class="container-fluid">
            <!-- Nav -->
            <nav class="js-mega-menu navbar navbar-expand-lg u-header__navbar">
                <!-- Logo -->
                <div class="u-header__navbar-brand-wrapper">
                    <a style="float: right;"
                        class="navbar-brand u-header__navbar-brand p-0"
                        [routerLink]="'/home'"
                        aria-label="Dead">
                        <img class="img-fluid"
                            src="../../../assets/svg/logos/dead_header_logo.svg"
                            alt="DEAD">
                    </a>
                </div>
                <!-- End Logo -->
                <!-- Responsive Toggle Button -->
                <button type="button"
                    class="navbar-toggler btn u-hamburger u-header__hamburger"
                    aria-label="Toggle navigation"
                    aria-expanded="false"
                    aria-controls="navBar"
                    data-toggle="collapse"
                    data-target="#navBar">
                    <span class="d-none d-sm-inline-block">Menu</span>
                    <span id="hamburgerTrigger"
                        class="u-hamburger__box ml-3">
                        <span class="u-hamburger__inner"></span>
                    </span>
                </button>
                <!-- End Responsive Toggle Button -->
                <!-- Navigation -->
                <div id="navBar"
                    class="collapse navbar-collapse u-header__navbar-collapse py-0">
                    <ul class="navbar-nav u-header__navbar-nav">
                        <!-- Home -->
                        <li class="nav-item u-header__nav-item">
                            <a id="homeMegaMenu"
                                class="nav-link u-header__nav-link"
                                [routerLink]="'/home'"
                                routerLinkActive="active-link"
                                (click)="closeNav()"> Home <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        <!-- End Home -->
                        <!-- Resources Dropdown -->
                        <!--<li class="nav-item hs-has-sub-menu u-header__nav-item"
                            data-event="hover"
                            data-animation-in="fadeInUp"
                            data-animation-out="fadeOut">
                            <a id="dropdownMegaMenu"
                                class="nav-link u-header__nav-link"
                                href="javascript:;"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-labelledby="dropdownSubMenu"> Resources <span
                                    class="fa fa-angle-down u-header__nav-link-icon"></span>
                            </a>
                            &lt;!&ndash; Resources Dropdown Submenu &ndash;&gt;
                            <ul id="dropdownSubMenu"
                                class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                                style="min-width: 220px;"
                                aria-labelledby="dropdownMegaMenu">
                                <li class="dropdown-item u-header__sub-menu-list-item py-0">
                                    <a class="nav-link u-header__sub-menu-nav-link"
                                        [routerLink]="'/nbe-review-manual'"
                                        routerLinkActive="active-link">Review Manual</a>
                                </li>
                                <li class="dropdown-item u-header__sub-menu-list-item py-0">
                                    <a class="nav-link u-header__sub-menu-nav-link"
                                        [routerLink]="'/nbe-tutoring-sessions-signup'"
                                        routerLinkActive="active-link">Tutoring Sessions</a>
                                </li>
                                <li class="dropdown-item u-header__sub-menu-list-item py-0">
                                    <a class="nav-link u-header__sub-menu-nav-link"
                                        [routerLink]="'/nbe-review-webinar-signup'"
                                        routerLinkActive="active-link">Review Webinar Series</a>
                                </li>
                            </ul>
                            &lt;!&ndash; Resources Dropdown Submenu &ndash;&gt;
                        </li>-->
                        <!-- End Resources Dropdown -->
                        <!-- Student-->
                        <!--<li *ngIf="isStudent"
                            class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                                class="nav-link u-header__nav-link"
                                (click)="closeNav()"
                                routerLinkActive="active-link"
                                [routerLink]="'/student'"> Student <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>-->
                        <li *ngIf="isStudent"
                            class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                               class="nav-link u-header__nav-link"
                               (click)="closeNav()"
                               routerLinkActive="active-link"
                               [routerLink]="'/game'"> Game <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        <!-- End Student-->
                        <!-- Student-->
                        <!--<li *ngIf="userSchoolId && isInstructor"
                            class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                                class="nav-link u-header__nav-link"
                                (click)="closeNav()"
                                routerLinkActive="active-link"
                                [routerLink]="['/instructor', userSchoolId]"> Instructor <i
                                    class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        &lt;!&ndash; End Student&ndash;&gt;
                        &lt;!&ndash; Admin&ndash;&gt;
                        <li class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                                class="nav-link u-header__nav-link"
                                *ngIf="isAdmin"
                                routerLinkActive="active-link"
                                (click)="closeNav()"
                                [routerLink]="'/admin/questions'"> Admin <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        &lt;!&ndash; End Admin&ndash;&gt;
                        &lt;!&ndash; Account &ndash;&gt;
                        <li class="nav-item u-header__nav-item"
                            *ngIf="isUser">
                            <a id="adminMegaMenu"
                                style="cursor: pointer;"
                                class="nav-link u-header__nav-link"
                                routerLinkActive="active-link"
                                [routerLink]="['/account']"
                                (click)="closeNav()"> Account <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        &lt;!&ndash; End Account &ndash;&gt;
                        <li class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                                class="nav-link u-header__nav-link"
                                (click)="closeNav()"
                                [routerLink]="'/contact-us'"
                                routerLinkActive="active-link">Contact Us <i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>
                        <li class="nav-item u-header__nav-item">
                            <a id="adminMegaMenu"
                                class="nav-link u-header__nav-link"
                                (click)="closeNav()"
                                [routerLink]="'/frequently-asked-questions'"
                                routerLinkActive="active-link">Help<i class="u-header__nav-link-icon"></i>
                            </a>
                        </li>-->
                        <!-- Button -->
                        <li class="nav-item u-header__nav-item-btn">
                            <button *ngIf="!isLoggedIn"
                                class="btn btn-sm btn-primary"
                                role="button"
                                (click)="login()">
                                <fa-icon [icon]="faUserCircle"
                                    [classes]="['fa', 'mr-1']"></fa-icon> Login
                            </button>
                            <button *ngIf="isLoggedIn"
                                class="btn btn-sm btn-primary"
                                role="button"
                                (click)="logout()">
                                <fa-icon [icon]="faUserCircle"
                                    [classes]="['fa', 'mr-1']"></fa-icon> Logout
                            </button>
                        </li>
                        <!-- End Button -->
                    </ul>
                </div>
                <!-- End Navigation -->
            </nav>
            <!-- End Nav -->
        </div>
    </div>
</header>
<!-- ========== END HEADER ========== -->
<!-- Go to Top -->
<a class="js-go-to u-go-to"
    href="javascript:;"
    data-position='{"bottom": 15, "right": 15 }'
    data-type="fixed"
    data-offset-top="400"
    data-compensation="#header"
    data-show-effect="slideInUp"
    data-hide-effect="slideOutDown">
    <fa-icon class="u-go-to__inner"
        [icon]="faArrowUp"></fa-icon>
</a>
<!-- End Go to Top -->
