import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
    selector: 'dead-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements AfterViewInit {

    constructor(private scroller: ViewportScroller) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            jQuery['HSCore'].components.HSStickyBlock.init('.js-sticky-block');
        }, 300);
    }

    scrollTo(anchor: string): void {
        this.scroller.scrollToAnchor(anchor);
    }

}
