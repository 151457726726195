import { Inject, Injectable } from "@angular/core";
import {
  EndSessionRequest,
  AuthorizationUrlRequest,
  RedirectRequest,
  SilentRequest,
  AuthenticationResult,
} from "@azure/msal-browser";
import { B2CPolicyFlows } from "../../core/constants";
import { Observable } from "rxjs";
import { isEmpty, isUndefined, get, some, first } from "lodash";
import { MsalService, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";

@Injectable()
export class DEADMsalService {
  constructor(private msalService: MsalService) {}

  login(redirectUrl: string = ""): Observable<void> {
    const data = {
      authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNIN_FLOW}`,
      state: redirectUrl ? redirectUrl : "/home",
      scopes: B2CPolicyFlows.B2C_SCOPES,
      extraScopesToConsent: B2CPolicyFlows.B2C_SCOPES_EXTRA,
    } as AuthorizationUrlRequest;

    return this.msalService.loginRedirect(data as RedirectRequest);
  }

  passwordReset(redirectUrl: string = ""): Observable<void> {
    const data = {
      authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_PASSWORD_RESET_FLOW}`,
      state: redirectUrl ? redirectUrl : "/home/",
      scopes: B2CPolicyFlows.B2C_SCOPES,
      extraScopesToConsent: B2CPolicyFlows.B2C_SCOPES_EXTRA,
    } as AuthorizationUrlRequest;

    return this.msalService.loginRedirect(data as RedirectRequest);
  }

  editUser(redirectUrl: string = ""): Observable<void> {
    const data = {
      authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_PROFILE_EDIT_FLOW}`,
      state: redirectUrl ? redirectUrl : "/home",
      scopes: B2CPolicyFlows.B2C_SCOPES,
      extraScopesToConsent: B2CPolicyFlows.B2C_SCOPES_EXTRA,
    } as AuthorizationUrlRequest;

    return this.msalService.loginRedirect(data as RedirectRequest);
  }
  isLoggedIn(): boolean {
    return some(this.msalService.instance.getAllAccounts());
  }
  goToSignup(redirectUrl: string = ""): Observable<void> {
    const data = {
      authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNUP_FLOW}`,
      state: redirectUrl ? redirectUrl : "/home",
      scopes: B2CPolicyFlows.B2C_SCOPES,
      extraScopesToConsent: B2CPolicyFlows.B2C_SCOPES_EXTRA,
    } as AuthorizationUrlRequest;

    return this.msalService.loginRedirect(data as RedirectRequest);
  }

  acquireTokenSilent(): Observable<AuthenticationResult> {
    const account = this.msalService.instance.getAllAccounts()[0];
    const data = {
      authority: `${B2CPolicyFlows.AUTHORITY_URL}/${B2CPolicyFlows.B2C_SIGNIN_FLOW}`,
      scopes: B2CPolicyFlows.B2C_SCOPES,
      account,
      forceRefresh: true,
    } as SilentRequest;

    return this.msalService.acquireTokenSilent(data);
  }

  isAdmin() {
    const allAccounts = this.msalService.instance.getAllAccounts();

    const acct: any = first(allAccounts)?.idTokenClaims;
    return (
      !isEmpty(acct) &&
      !isUndefined(acct.groups.find((g) => g.toLowerCase() === "admin"))
    );
  }
  isStudent() {
    const acct: any =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    return (
      !isEmpty(acct) &&
      !isUndefined(acct.groups.find((g) => g.toLowerCase() === "student"))
    );
  }
  isInstructor() {
    const acct: any =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    return (
      !isEmpty(acct) &&
      !isUndefined(acct.groups.find((g) => g.toLowerCase() === "instructor"))
    );
  }

  logout(redirectUrl: string = ""): Observable<void> {
    let data: EndSessionRequest = {};
    if (redirectUrl) {
      data.postLogoutRedirectUri = redirectUrl;
    }
    return this.msalService.logoutRedirect(data);
  }

  getSubExirationDate(): Date {
    const acct: any =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    return new Date(get(acct, "extension_StudentSubExpirationDate"));
  }

  getGrauationdDate(): Date {
    const acct: any =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    return new Date(get(acct, "extension_GraduationDate"));
  }

  getSchoolId(): string {
    const acct: any =
      this.msalService.instance.getAllAccounts()[0].idTokenClaims;
    return get(acct, "extension_SchoolId");
  }

  getAllGroups(): string[] {
    return ["Admin", "Student", "Instructor"];
  }

  getClaims(): any {
    return this.msalService.instance.getAllAccounts()[0].idTokenClaims;
  }
}
