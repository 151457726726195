import {
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { PurchaseHistoryFilter } from 'src/app/core/models/purchase-history-filter.model';
import { PurchaseSubscription } from 'src/app/shared/purchaseSubscription';
import { environment } from 'src/environments/environment';
import { loadPurchases, loadPurchasesForCurrentUser, loadPurchasesForCurrentUserSuccess,
     loadPurchasesSuccess, updatePurchaseHistoryFilter } from '../actions/purchase.actions';

export const purchaseFeatureKey = 'purchase';

export interface PurchaseState {
    purchasesForCurrentUser: PurchaseSubscription[],
    purchases: PurchaseSubscription[],
    purchasesLoading: boolean,
    purchaseHistoryFilter: PurchaseHistoryFilter
}

const initialState: PurchaseState = {
    purchasesForCurrentUser: [],
    purchases: [],
    purchasesLoading: false,
    purchaseHistoryFilter: {}
}

export const reducers = createReducer(
    initialState,
    on(loadPurchasesForCurrentUser, (state) => ({ ...state, purchasesForCurrentUser: [], purchasesLoading: true })),
    on(loadPurchasesForCurrentUserSuccess, (state, { purchases }) => ({
        ...state, purchasesForCurrentUser: purchases,
        purchasesLoading: false
    })),
    on(loadPurchases, (state) => ({ ...state, purchases: [], purchasesLoading: true })),
    on(loadPurchasesSuccess, (state, { purchases }) => ({ ...state, purchases, purchasesLoading: false })),
    on(updatePurchaseHistoryFilter, (state, { purchaseHistoryFilter }) => {
        const newPurchaseHistoryFilter = {...state.purchaseHistoryFilter, ...purchaseHistoryFilter}
        const newState = {...state, purchaseHistoryFilter: newPurchaseHistoryFilter};
        return newState;
    }
))

export const metaReducers: MetaReducer<PurchaseState>[] = !environment.production ? [] : [];

// selectors
export const getPurchaseState = createFeatureSelector<PurchaseState>(purchaseFeatureKey);

export const getPurchasesForCurrentUserFromState = createSelector(getPurchaseState, (state): PurchaseSubscription[] => state.purchasesForCurrentUser);
export const getPurchasesFromState = createSelector(getPurchaseState, (state): PurchaseSubscription[] => state.purchases);
export const isLoadingPurchases = createSelector(getPurchaseState, (state): boolean => state.purchasesLoading);
