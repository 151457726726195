import {
    createFeatureSelector,
    createReducer,
    createSelector,
    MetaReducer,
    on
} from '@ngrx/store';
import { User } from 'src/app/core/models/user.model';
import { environment } from 'src/environments/environment';
import { addEditDeleteUserFailure, loadCurrentUser, loadCurrentUserSuccess, loadUsers, loadUsersSuccess, loadUsersWithFilter, reloadUsers, updateCurrUserComplete, updateCurrUserDetails, updateCurrUserGradDate, updateCurrUserFailure, updateUserDetails, updateUserGradDate, updateUserGroups, updateUserSchool, updateUserSubscription, exportUsers, exportUsersFinished } from '../actions/user.actions';

export const userFeatureKey = 'user';

export interface UserState {
    users: User[],
    usersLoading: boolean,
    exportingUsers: boolean,
    currentUser: User,
}

const initialState: UserState = {
    users: [],
    usersLoading: false,
    exportingUsers: false,
    currentUser: null,
}

export const reducers = createReducer(
    initialState,
    on(loadCurrentUser, (state) => ({ ...state, currentUser: null, usersLoading: true })),
    on(loadCurrentUserSuccess, (state, { user }) => ({ ...state, currentUser: user, usersLoading: false })),
    on(loadUsers, reloadUsers, loadUsersWithFilter, (state) => ({ ...state, users: [], usersLoading: true })),
    on(loadUsersSuccess, (state, { users }) => ({ ...state, users, usersLoading: false })),
    on(
        updateUserSchool,
        updateUserSubscription,
        updateUserGroups,
        updateUserGradDate,
        updateUserDetails,
        (state) => ({ ...state, usersLoading: true })),
    on(addEditDeleteUserFailure, (state) => ({ ...state, usersLoading: false })),
    on(updateCurrUserDetails, updateCurrUserGradDate, (state) => ({ ...state, usersLoading: true })),
    on(updateCurrUserComplete, (state, { user }) => ({ ...state, currentUser: user, usersLoading: false })),
    on(updateCurrUserFailure, (state) => ({ ...state, usersLoading: false })),
    on(exportUsers, (state) => ({...state, exportingUsers: true})),
    on(exportUsersFinished, (state)=>({...state, exportingUsers: false}))
);

export const metaReducers: MetaReducer<UserState>[] = !environment.production ? [] : [];

// selectors
export const getUserState = createFeatureSelector<UserState>(userFeatureKey);
export const getUsersFromState = createSelector(getUserState, (state): User[] => state.users);
export const isLoadingUsers = createSelector(getUserState, (state): boolean => state.usersLoading);
export const getCurrentUserFromState = createSelector(getUserState, (state): User => state.currentUser);
export const isExportingUsers = createSelector(getUserState, (state): boolean => state.exportingUsers);
