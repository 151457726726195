import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';
import {
    addEditDeletePurchasesComplete, addEditDeletePurchasesFailure,
    deletePurchases, loadPurchases, loadPurchasesFailure, loadPurchasesForCurrentUser,
    loadPurchasesForCurrentUserFailure, loadPurchasesForCurrentUserSuccess, loadPurchasesSuccess,
    markBulkPurchasesPaid, markBulkPurchasesPaidComplete, markBulkPurchasesPaidFailure
} from '../actions/purchase.actions';
import { reloadVoucherCodes } from '../admin/actions/voucher-code.actions';
import { SubscriptionsService } from '../core/services/subscriptions.service';
import { ToastService } from '../core/services/toast.service';
import { updatePurchaseHistory } from '../subscriptions/actions/subscription.actions';

@Injectable()
export class PurchaseEffects {

    constructor(
        private actions$: Actions,
        private subscriptionsService: SubscriptionsService,
        private toastService: ToastService
    ) { }

    loadPurchasesForCurrentUser$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadPurchasesForCurrentUser),
                exhaustMap(() =>
                    this.subscriptionsService.getPurchasedSubscriptionsForUser()
                        .pipe(
                            map((purchases) => {
                                return loadPurchasesForCurrentUserSuccess({ purchases });
                            }),
                            catchError((error) => {
                                return of(loadPurchasesForCurrentUserFailure({ error }));
                            })
                        )
                )
            )
    );

    loadPurchases$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadPurchases),
                exhaustMap(() =>
                    this.subscriptionsService.getPurchasedSubscriptions()
                        .pipe(
                            map((purchases) => {
                                return loadPurchasesSuccess({ purchases });
                            }),
                            catchError((error) => {
                                return of(loadPurchasesFailure({ error }));
                            })
                        )
                )
            )
    );

    deletePurchases$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deletePurchases),
                map(p => p.purchaseIds),
                exhaustMap(purchaseIds =>
                    this.subscriptionsService.deletePurchaseHistory(purchaseIds)
                        .pipe(
                            map(() => {
                                return addEditDeletePurchasesComplete({ redirectUrl: '' });
                            }),
                            catchError((error) => {
                                return of(addEditDeletePurchasesFailure({ error }));
                            })
                        )
                )
            )
    );

    addEditDeletePurchasesComplete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addEditDeletePurchasesComplete, markBulkPurchasesPaidComplete),
                switchMap(() => of(loadPurchases()))
            )
    );

    markBulkPurchasesPaid$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(markBulkPurchasesPaid),
                map(p => p.purchaseIds),
                exhaustMap(purchaseIds =>
                    this.subscriptionsService.setPaidBulkPurchases(purchaseIds)
                        .pipe(
                            map(() => {
                                return markBulkPurchasesPaidComplete({ redirectUrl: '' });
                            }),
                            catchError((error) => {
                                return of(markBulkPurchasesPaidFailure({ error }));
                            })
                        )
                )
            )
    );

    updatePurchaseHistory$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updatePurchaseHistory),
                exhaustMap(({ id, userEmail, sendEmail }) =>
                    this.subscriptionsService.updatePurchaseHistory(id, userEmail, sendEmail)
                        .pipe(
                            mergeMap(() => {
                                this.toastService.success('Voucher codes sent.');
                                return [
                                    reloadVoucherCodes(),
                                    addEditDeletePurchasesComplete({ redirectUrl: null })
                                ];

                            }),
                            catchError((error) => {
                                this.toastService.error('Unable to re-send voucher codes.');
                                return of(addEditDeletePurchasesFailure({ error }));
                            })
                        )
                )
            )
    );

}
