import { AuthenticationResult, AuthError, BrowserAuthError } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';
import { MsalState } from '../reducers/msal.reducers';

export const loginStarted = createAction(
    '[Msal] Login Started',
    props<{ redirectUrl: string }>()
);

export const loginResponseRecieved = createAction(
    '[Msal] Login Response Recieved',
    props<{ resp: AuthenticationResult }>()
);

export const setUserState = createAction(
    '[Msal] Set User State',
    props<{ newState: MsalState }>()
);

export const loginSuccess = createAction(
    '[Msal] Login Success',
    props<{ resp: AuthenticationResult }>()
);

export const loginComplete = createAction('[Msal] Login Complete');

export const loginFailure = createAction(
    '[Msal] Login Failure',
    props<{ error: AuthError }>()
);

export const loginFailureComplete = createAction('[Msal] Login Failure Complete');
