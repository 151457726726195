<!-- Privacy Section -->
<div class="bg-gray-100">
    <div class="container space-2 space-2-top--lg space-3-bottom--lg">
        <div class="w-lg-80 bg-white border rounded mx-lg-auto p-5 p-sm-7">
            <!-- Hero -->
            <div class="mb-9">
                <h1>Fulfillment Policy</h1>

                <p>
                    The following is the Fulfillment Policy for all goods and services provided by Anubis Publications
                    Inc via the www.deatheducationassessmentdrills.com website (Site).
                </p>
            </div>
            <!-- End Hero -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h2 class="h4">Service: Death Education Assessment Drills (D.E.A.D)</h2>
                </div>
                <ul class="list-unstyled">
                    <li>
                        Individual User Purchasing
                        <ul>
                            <li>
                                Upon successful payment via credit card the customer is granted immediate access to the
                                D.E.A.D
                                platform for the time period the customer selected. Subscriptions do not auto-renew.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Institutional Purchasing
                        <ul>
                            <li>
                                Upon successful payment via credit card or check, the Institution is provided with the
                                specific number
                                of voucher codes purchased via email. The vouchers are valid for a time period of
                                6-months from
                                activation; but never expire prior to use.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Refunds
                        <ul>
                            <li>
                                Refunds for individual purchases can be submitted in writing via email by using the
                                Contact Us form
                                online or by emailing info@deatheducationassessmentdrills.com. Refunds are pro-rated per
                                day remaining
                                on subscription calculated as follows:
                                <ul>
                                    <li>
                                        Per Day Rate
                                        <ul>
                                            <li>
                                                Subscription for 1 month: $1.00 per day
                                            </li>
                                            <li>
                                                Subscription for 3 months: $0.55 per day
                                            </li>
                                            <li>
                                                Subscription for 6 months: $0.44 per day
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        In the event of duplicative payments; refunds are made in full for the duplicate
                                        payment upon written
                                        notification.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Institutional purchases are final.
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Product: NBE Review Manual (AKA: Taggart’s Notes)</h3>
                </div>

                <ul class="list-unstyled">
                    <li>
                        Anubis manually processes printing, binding, packaging, and shipment via USPS to purchaser.
                    </li>
                    <li>
                        Under most circumstances, orders are shipped within 48 hours.
                    </li>
                    <li>
                        Returns/Exchanges
                        <ul>
                            <li>
                                Individual Purchasers
                                <ul>
                                    <li>
                                        All sales are final
                                    </li>
                                    <li>
                                        If product is damaged beyond use in shipping then a replacement will be provided
                                        after receipt of a written notification (Contact Us Form or email to
                                        info@deatheducationassessmentdrills.com) with accompanying picture evidence of
                                        damage. We will also provide a pre-paid return packaging envelope for the
                                        damaged product.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Institutional Purchasers
                                <ul>
                                    <li>
                                        All sales are final.
                                    </li>
                                    <li>
                                        If a new edition is published and the institution has remaining stock of the
                                        previous edition, we will
                                        exchange 1:1. Institution only pays shipping cost and is expected to return the
                                        outdated manuals. This
                                        is done only upon request in writing to
                                        <a href="mailto:info@deatheducationassessmentdrills.com">
                                            info@deatheducationassessmentdrills.com
                                        </a>.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Service: Semi-Private Tutoring</h3>
                </div>

                <ul class="list-unstyled">
                    <li>
                        All tutoring sessions are conducted via Zoom.
                    </li>
                    <li>
                        Upon successful payment, a Zoom link is emailed instantly to the purchaser.
                    </li>
                    <li>
                        Refunds:
                        <ul>
                            <li>
                                All sales are final. On a case-by-case basis rescheduling is possible after written
                                request<br />
                                (
                                <a href="mailto:info@deatheducationassessmentdrills.com">
                                    info@deatheducationassessmentdrills.com
                                </a>
                                ).
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <!-- End Info Content -->

            <!-- Info Content -->
            <div class="mb-7">
                <div class="mb-3">
                    <h3 class="h4">Service: NBE Review Series</h3>
                </div>

                <ul class="list-unstyled">
                    <li>
                        Individual purchasers:
                        <ul>
                            <li>
                                Virtual sessions are conducted via Zoom. A Zoom link is emailed the day prior to the
                                session manually.
                            </li>
                            <li>
                                Purchasers are emailed the presentation materials and recordings of the sessions after
                                the completion of the session. Anyone who paid receives this email regardless of actual
                                attendance.
                            </li>
                            <li>
                                Purchasers are given 3-months access to D.E.A.D as part of registration fee. Vouchers
                                are emailed after the close of the session. Vouchers never expire.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Institutional purchasers:
                        <ul>
                            <li>
                                A Zoom link is emailed to the Institution manually the day prior to the session.
                            </li>
                            <li>
                                Institutional attendees are not provided with the materials; but are given the
                                recordings.
                            </li>
                            <li>
                                Institutional attendees are not provided with access to D.E.A.D.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Refunds:
                        <ul>
                            <li>
                                All sales are final. Attendees are provided with materials and recordings regardless of
                                actual
                                attendance.
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>
            <!-- End Info Content -->
        </div>
    </div>
</div>
<!-- End Privacy Section -->
