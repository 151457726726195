import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { isNull, isUndefined } from 'lodash';
import * as moment from 'moment';
import { defer, forkJoin, iif, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DbService extends Dexie {
    private dbTimeout = 30;

    constructor(private http: HttpClient) {
        super('DeadDb');
        this.version(5).stores({
            questionCategories: '++id,text,code,description,sortOrder,numOfQuestions',
            emailTemplates: '++id,code,text,subject,automationJobCode',
            questions: '++id,text,code,categoryId,typeId',
            schools: '++id,name,address1,address2,city,state,zip,fax,website,isDeleted',
            users: '++userId,firstName,lastName,email,subscriptionEndDate,schoolId,streetAddress,city,postalCode,country,state,graduationDate',
            data_timestamp: '++id,table,timestamp',
            questionErrors: '++id,userId,user,questionId,question,issue,isResolved,createDate',
            deadSubscriptions: 'name,code,description,price,isDeleted,isVisible',
            studentTestRuns: '++id,userId,createDate,completedDate,questionsCompleted',
            voucherCodes: 'code,subscriptionTypeCode,subscriptionTypeName,discountPercentage,isUsed,isDeleted,usedByEmail,issuedToEmail',
            suggestedQuestion: '++id,text,userId,user,categoryId,category,answer'
        })
    }

    deleteAllData(): Observable<any> {
        const tablesToClearCalls: Observable<void>[] = []
        this.tables.forEach(p => {
            tablesToClearCalls.push(defer(() => p.clear()));
        })

        return forkJoin(tablesToClearCalls);
    }

    updateRecord<T>(tableName: string, record: any, recordId: string): Observable<number>{
        return defer(async () => this.table<T, string>(tableName).update(recordId, record));
    }

    getAll<T>(tableName: string, url: string = ''): Observable<T[]> {
        try {
            const expireTable = defer(async () => await this.table('data_timestamp').where('table').equals(tableName).first());
        const expirationTime = moment().toDate();

        const clearTable = defer(async () => await this.table<T, string>(tableName).clear())
            .pipe(
                mergeMap(() => refreshData)
            );

        const refreshData = defer(() => {
            if (url) {
                return this.http.get(url)
            }
            return of([])
        }).pipe(
            mergeMap((i:T[]) => {
               return  defer(async () => {
                   try {
                       await this.table<T, string>(tableName).clear();
                       await this.table<T, string>(tableName).bulkAdd(i);
                   } catch (error) {
                       console.error('Error while adding data to table:', tableName, error);
                       // Handle error appropriately, e.g., throw or return a failure action
                   }
               })
            }),
            mergeMap(() => expireTable),
            mergeMap(p =>
                iif(
                    () => isNull(p) || isUndefined(p),
                    defer(async () => await this.table('data_timestamp')
                        .add({ table: tableName, timestamp: moment().add(this.dbTimeout, 'm').toDate() })),
                    defer(async () => await this.table('data_timestamp')
                        .update(p.id, { table: tableName, timestamp: moment().add(this.dbTimeout, 'm').toDate() }))
                )
            ),
            mergeMap(() => defer(async () => await this.table<T, string>(tableName).toArray())),
            map(res => {
                return res;
            })
        );

        return expireTable
            .pipe(
                mergeMap(p =>
                    iif(() =>
                        !p || p.timestamp < expirationTime,
                        clearTable,
                        defer(async () => await this.table<T, string>(tableName).toArray())
                            .pipe(mergeMap(t => iif(() => t.some(p => p) && t.length > 0, of(t), refreshData)))
                    )
                ),
                map((p: T[]) => p),
                map(res => {
                    return res;
                })
            );
        } catch (error) {
            return this.http.get<T[]>(url);
        }
    }

    getFilter<T>(tableName: string, prop: string, filter: string, url: string = ''): Observable<T> {
        const expireTable = defer(async () => await this.table('data_timestamp').where('table').equals(tableName).first());
        const expirationTime = moment().toDate();

        const clearTable = defer(async () => await this.table<T, string>(tableName).clear())
            .pipe(mergeMap(() => {
                if (url) {
                    return this.http.get(url);
                }

                return of({});
            }));

        return expireTable
            .pipe(
                mergeMap(p =>
                    iif(() =>
                        !p || p.timestamp < expirationTime,
                        clearTable,
                        defer(async () => await this.table<T, string>(tableName).where(prop).equals(filter).first())
                    )
                ),
                map((p: T) => p)
            );
    }

    clearTableData<T>(tableName: string): Observable<void> {
        const expireTable = defer(async () => await this.table('data_timestamp').where('table').equals(tableName).first());

        return expireTable
            .pipe(
                mergeMap(p =>
                    iif(
                        () => isNull(p) || isUndefined(p),
                        of(null),
                        defer(async () => {
                            await this.table('data_timestamp').delete(p.id);
                        })
                    )
                ),
                mergeMap(async () => {
                    await this.table<T, string>(tableName).clear();
                }),
            );
    }
}
