import { createAction, props } from '@ngrx/store';
import { PurchaseSubscription } from '../shared/purchaseSubscription';
import { PurchaseHistoryFilter } from 'src/app/core/models/purchase-history-filter.model';

export const loadPurchasesForCurrentUser = createAction('[PurchaseHistory] Load Purchases For Current User');

export const loadPurchases = createAction('[PurchaseHistory] Load Purchases');

export const reloadPurchases = createAction('[PurchaseHistory] Reload Purchases');

export const loadPurchasesForCurrentUserSuccess = createAction(
    '[PurchaseHistory] Load Purchases For Current User Success',
    props<{ purchases: PurchaseSubscription[] }>()
);

export const loadPurchasesForCurrentUserFailure = createAction(
    '[PurchaseHistory] Load Purchases For Current User Failure',
    props<{ error: any }>()
);

export const loadPurchasesSuccess = createAction(
    '[PurchaseHistory] Load Purchases Success',
    props<{ purchases: PurchaseSubscription[] }>()
);

export const loadPurchasesFailure = createAction(
    '[PurchaseHistory] Load Purchases Failure',
    props<{ error: any }>()
);
export const addPurchase = createAction(
    '[PurchaseHistory] Add Purchase',
    props<{ purchase: PurchaseSubscription, redirectUrl: string }>()
);

export const editPurchase = createAction(
    '[PurchaseHistory] Edit Purchase',
    props<{ purchase: PurchaseSubscription, redirectUrl: string }>()
);

export const deletePurchases = createAction(
    '[PurchaseHistory] Delete Purchases',
    props<{ purchaseIds: string[] }>()
);

export const addEditDeletePurchasesComplete = createAction(
    '[PurchaseHistory] Add/Edit/Delete Purchases Complete',
    props<{ redirectUrl: string }>()
);

export const addEditDeletePurchasesFailure = createAction(
    '[PurchaseHistory] Add/Edit/Delete Purchases Failure',
    props<{ error: any }>()
);

export const markBulkPurchasesPaid = createAction(
    '[PurchaseHistory] Mark Bulk Purchases Paid',
    props<{ purchaseIds: string[] }>()
);

export const markBulkPurchasesPaidComplete = createAction(
    '[PurchaseHistory] Mark Bulk Purchases Paid Complete',
    props<{ redirectUrl: string }>()
);

export const markBulkPurchasesPaidFailure = createAction(
    '[PurchaseHistory] Mark Bulk Purchases Paid Failure',
    props<{ error: any }>()
);

export const updatePurchaseHistoryFilter = createAction(
    '[PurchaseHistory] Update Purchase History Filter',
    props<{ purchaseHistoryFilter: PurchaseHistoryFilter }>()
);
