import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { addFaq, deleteFaqs, editFaq, loadFaqs, updateFaqs } from '../actions/faq.actions';
import { FaqService } from '../core/services/faq.service';
import { ToastService } from '../core/services/toast.service';




@Injectable()
export class FaqEffects {

    constructor(
        private actions$: Actions,
        private faqService: FaqService,
        private toastService: ToastService,
        private router: Router

    ) { }

    loadFaqs$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadFaqs),
                exhaustMap(() =>
                    this.faqService.readAll()
                        .pipe(
                            map((faqs) => {
                                return updateFaqs({newState: {error: '', isLoading: false, faqs}});
                            }),
                            catchError((error) => {
                                return of(updateFaqs({ newState: {error, isLoading: false, faqs: []} }));
                            })
                        )
                )
            )
    );
    addFaq$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addFaq),
                exhaustMap((val) =>
                    this.faqService.create(val.faq)
                        .pipe(
                            map(() => {
                                this.router.navigate(['/admin/faq']);
                                return loadFaqs();
                            }),
                            catchError((error) => {
                                return of(updateFaqs({ newState: {error, isLoading: false, faqs: []} }));
                            })
                        )
                )
            )
    );

    editFaq$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editFaq),
                exhaustMap((val) =>
                    this.faqService.update(val.faq)
                        .pipe(
                            map(() => {
                                this.router.navigate(['/admin/faq']);
                                return loadFaqs();
                            }),
                            catchError((error) => {
                                return of(updateFaqs({ newState: {error, isLoading: false, faqs: []} }));
                            })
                        )
                )
            )
    );


    deleteFaq$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteFaqs),
                exhaustMap((val) =>
                    this.faqService.deleteList(val.ids)
                        .pipe(
                            map(() => {
                                this.router.navigate(['/admin/faq']);
                                return loadFaqs();
                            }),
                            catchError((error) => {
                                return of(updateFaqs({ newState: {error, isLoading: false, faqs: []} }));
                            })
                        )
                )
            )
    );
}
