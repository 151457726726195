import { Component, AfterViewInit } from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'dead-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
    faPlay = faPlay;

    constructor() { }

    ngAfterViewInit(): void {
        // initialization of fancybox
        jQuery['HSCore'].components.HSFancyBox.init('.js-fancybox');

        // initialization of cubeportfolio
        jQuery['HSCore'].components.HSCubeportfolio.init('.cbp');

        // initialization of counters
        jQuery['HSCore'].components.HSCounter.init('[class*="js-counter"]');

        // initialization of slick carousel
        jQuery['HSCore'].components.HSSlickCarousel.init('.js-slick-carousel');
    }
}
