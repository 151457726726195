<div class="modal-header">
    <h4 class="modal-title">Are you still there?</h4>
</div>
<div class="modal-body">
    <p>
        Your session will expire in {{ startCount - count }} seconds. Logout or
        hit continue to stay logged in.
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="continue()">
        Continue
    </button>
    <button type="button" class="btn btn-sm btn-danger" (click)="logout()">
        Logout
    </button>
</div>
