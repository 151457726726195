import { HttpParams, HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { chunk } from 'lodash';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class HttpUtilService {

    constructor(private http: HttpClient) { }

    bulkDelete(ids: string[], propName: string, endpoint: string): Observable<any> {
        const deletes$ = [];
        const size = 50;
        if (ids.length < size) {
            let params = new HttpParams();
            ids.forEach((qId) => {
                params = params.append(propName, qId);
            });

            deletes$.push(this.http.delete(
                endpoint,
                { params }))
        }
        else {
            let params = new HttpParams();
            const chunks = chunk(ids, size);
            chunks.forEach((chunkList) => {
                chunkList.forEach((qId) => {
                    params = params.append(propName, qId);
                });
                deletes$.push(this.http.delete(
                    endpoint,
                    { params }));
                params = new HttpParams();
            });
        }
        return forkJoin(deletes$)
    }
}
