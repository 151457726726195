import { createAction, props } from '@ngrx/store';
import { ContactUs } from '../core/models/contact-us.model';
import { EmailTemplate } from '../core/models/email-templates.model';
import { GenericEmail } from '../core/models/generic-email.model';

export const loadEmailTemplates = createAction('[Email] Load Email Templates');

export const reloadEmailTemplates = createAction('[Email] Reload Email Templates');

export const loadEmailTemplatesSuccess = createAction(
    '[Email] Load Email Templates Success',
    props<{ emailTemplates: EmailTemplate[] }>()
);

export const loadEmailTemplatesFailure = createAction(
    '[Email] Load Email Templates Failure',
    props<{ error: any }>()
);

export const addEmailTemplate = createAction(
    '[Email] Add Email Template',
    props<{ emailTemplate: EmailTemplate, redirectUrl: string }>()
);

export const editEmailTemplate = createAction(
    '[Email] Edit Email Template',
    props<{ emailTemplate: EmailTemplate, redirectUrl: string }>()
);

export const deleteEmailTemplate = createAction(
    '[Email] Delete Email Templates',
    props<{ emailTemplateId: string }>()
);

export const addEditDeleteEmailTemplateComplete = createAction(
    '[Email] Add/Edit/Delete Email Template Complete',
    props<{ redirectUrl: string }>()
);

export const addEditDeleteEmailTemplateFailure = createAction(
    '[Email] Add/Edit/Delete Email Template Failure',
    props<{ error: any }>()
);

export const sendBulkEmailRequest = createAction(
    '[Email] Send Bulk Email Request',
    props<{ email: GenericEmail }>()
);

export const sendBulkEmailRequestComplete = createAction(
    '[Email] Send Bulk Email Request Complete'
);

export const sendBulkEmailRequestFailure = createAction(
    '[Email] Send Bulk Email Request Failure',
    props<{ error: any }>()
);

export const sendContactUsRequest = createAction(
    '[Email] Send Contact Us Request',
    props<{ contactUsRequest: ContactUs }>()
);

export const sendContactUsRequestComplete = createAction(
    '[Email] Send Contact Us Complete'
);

export const sendContactUsRequestFailure = createAction(
    '[Email] Send Contact Us Request Failure',
    props<{ error: any }>()
);
