import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Faq } from '../models/faq.model';
import { HttpUtilService } from './../../shared/http-util.service';

@Injectable({
    providedIn: 'root'
})
export class FaqService {
    faqApiBase = 'faq';

    constructor(
        private http: HttpClient,
        private httpUtils: HttpUtilService) { }


    create(faq: Faq): Observable<any> {
        return this.http.post(
            `${environment.ADMIN_API_BASE}${this.faqApiBase}`,
            faq
        );
    }
    read(id: string): Observable<Faq> {
        return this.http.get<Faq>(`${environment.ADMIN_API_BASE}${this.faqApiBase}/${id}`);
    }

    readAll(showDeleted: boolean = false): Observable<Faq[]> {
        let url = `${environment.GENERAL_API_BASE}${this.faqApiBase}/list`;
        if (showDeleted) {
            url += `/${showDeleted}`;
        }

        return this.http.get<Faq[]>(url);
    }

    update(faq: Faq): Observable<any> {
        return this.http.put(
            `${environment.ADMIN_API_BASE}${this.faqApiBase}`,
            faq
        );
    }

    delete(id: string): Observable<any> {
        return this.http.delete(
            `${environment.ADMIN_API_BASE}${this.faqApiBase}/delete/${id}`
        );
    }

    deleteList(ids: string[]): Observable<any> {
        return this.httpUtils.bulkDelete(ids, 'ids', `${environment.ADMIN_API_BASE}${this.faqApiBase}/bulk/delete`);
    }
}
