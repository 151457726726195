import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { createAction, props } from '@ngrx/store';

export const editUserStarted = createAction(
    '[Msal] Edit User Started',
    props<{ redirectUrl: string }>()
);

export const editUserResponseRecieved = createAction(
    '[Msal] Edit User Response Recieved',
    props<{ resp: AuthenticationResult }>()
);

export const editUserSuccess = createAction(
    '[Msal] Edit User Success',
    props<{ isAdmin: boolean, isInstructor: boolean, isStudent: boolean, schoolId: string, subExirationDate: Date, resp: AuthenticationResult }>()
);

export const editUserComplete = createAction('[Msal] Edit User Complete');

export const editUserFailure = createAction(
    '[Msal] Edit User Failure',
    props<{ error: AuthError }>()
);

