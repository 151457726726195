import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModalModule, NgbPaginationModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BillingInfoComponent } from './billing-info/billing-info.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouteComponent } from './route/route.component';
import { SchoolInfoComponent } from './school-info/school-info.component';
import { ChartComponent } from './student-dashboard/chart/chart.component';
import { QuestionErrorReportModalComponent } from './student-dashboard/question-error-report-modal/question-error-report-modal.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { TestGenModalComponent } from './student-dashboard/test-gen-modal/test-gen-modal.component';
import { SubscriptionTypesComponent } from './subscription-types/subscription-types.component';
import { TestResultQuestionComponent } from './test-results/test-result-question/test-result-question.component';
import { TestResultsComponent } from './test-results/test-results.component';
import { UpdateGradDateModalComponent } from './user-info/update-grad-date-modal/update-grad-date-modal.component';
import { UpdateGroupsModalComponent } from './user-info/update-groups-modal/update-groups-modal.component';
import { UpdateSchoolModalComponent } from './user-info/update-school-modal/update-school-modal.component';
import { UpdateSubscriptionModalComponent } from './user-info/update-subscription-modal/update-subscription-modal.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserPurchaseHistoryComponent } from './user-purchase-history/user-purchase-history.component';
import { YesNoModalComponent } from './yes-no-modal/yes-no-modal.component';
import { GenTestConfirmationModalComponent } from './student-dashboard/gen-test-confirmation-modal/gen-test-confirmation-modal.component';
@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        BillingInfoComponent,
        RouteComponent,
        SubscriptionTypesComponent,
        YesNoModalComponent,
        StudentDashboardComponent,
        TestResultsComponent,
        TestResultQuestionComponent,
        QuestionErrorReportModalComponent,
        ChartComponent,
        TestGenModalComponent,
        UserInfoComponent,
        UpdateSubscriptionModalComponent,
        UpdateSchoolModalComponent,
        UpdateGradDateModalComponent,
        UpdateGroupsModalComponent,
        SchoolInfoComponent,
        UserPurchaseHistoryComponent,
        GenTestConfirmationModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule,
        RouterModule,
        FontAwesomeModule,
        NgSelectModule,
        NgbTooltipModule,
        NgbPaginationModule,
        NgbProgressbarModule,
        NgbModalModule,
        BsDatepickerModule,
        NgxDatatableModule,
        PopoverModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        BillingInfoComponent,
        RouteComponent,
        SubscriptionTypesComponent,
        StudentDashboardComponent,
        TestResultsComponent,
        TestResultQuestionComponent,
        QuestionErrorReportModalComponent,
        ChartComponent,
        TestGenModalComponent,
        UserInfoComponent,
        UpdateSubscriptionModalComponent,
        UpdateSchoolModalComponent,
        UpdateGradDateModalComponent,
        UpdateGroupsModalComponent,
        SchoolInfoComponent,
        UserPurchaseHistoryComponent,
        PopoverModule
    ]
})
export class SharedModule { }
