import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { logoutStarted } from '../actions/msal-logout.actions';
import { timeoutStopTimer } from '../actions/timeout.actions';
import { MsalState } from '../reducers/msal.reducers';

@Component({
    selector: 'dead-timeout-modal',
    templateUrl: './timeout-modal.component.html',
    styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent {
    @Input() count: number;
    startCount = 30;

    constructor(
        private msalStore: Store<MsalState>,
        private activeModal: NgbActiveModal,
    ) { }

    continue() {
        this.msalStore.dispatch(timeoutStopTimer());
        this.activeModal.close();
    }

    logout() {
        this.msalStore.dispatch(logoutStarted({ redirectUrl: '' }));
        this.activeModal.close();
    }
}
