<div class="modal-header">
    <h4 class="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
    <span [innerHTML]="body"></span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="activeModal.close()">{{continueBtnTxt}}</button>
    <button *ngIf="cancelBtnTxt" type="button" class="btn btn-sm btn-danger"
        (click)="activeModal.dismiss()">{{cancelBtnTxt}}</button>
</div>
