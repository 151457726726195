import {
    createFeatureSelector,
    createReducer,


    on
} from '@ngrx/store';
import { loadFaqs, updateFaqs } from '../actions/faq.actions';
import { Faq } from '../core/models/faq.model';

export const faqFeatureKey = 'faq';

export interface FaqState {
    faqs: Faq[],
    isLoading: boolean,
    error: string
}

const initialState: FaqState = {
    faqs: [],
    isLoading: false,
    error: ''
}

export const reducers = createReducer(
    initialState,
    on(loadFaqs, (state) => ({ ...state, isLoading: true, error:'' })),
    on(updateFaqs, (state, { newState }) => ({ ...state, ...newState })),

);


// selectors
export const getFaqState = createFeatureSelector<FaqState>(faqFeatureKey);
