import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import {
    Action,
    ActionReducer,
    ActionReducerMap,
    MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { AppEffects } from '../effects/app.effects';
import { EmailEffects } from '../effects/email.effects';
import { FaqEffects } from '../effects/faq.effects';
import { PurchaseEffects } from '../effects/purchase.effects';
import { RoutingEffects } from '../effects/routing.effects';
import { SchoolEffects } from '../effects/school.effects';
import { UserEffects } from '../effects/user.effects';
import * as emailReducer from './email.reducers';
import * as faqReducer from './faq.reducer';
import * as puchaseReducer from './purchase.reducers';
import * as schoolReducer from './school.reducers';
import * as userReducer from './user.reducers';




export interface State {
    [emailReducer.emailFeatureKey]: emailReducer.EmailState;
    [puchaseReducer.purchaseFeatureKey]: puchaseReducer.PurchaseState;
    [userReducer.userFeatureKey]: userReducer.UserState;
    [schoolReducer.schoolFeatureKey]: schoolReducer.SchoolState;
    [faqReducer.faqFeatureKey]: faqReducer.FaqState,
    router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>('Root reducers token', {
    factory: () => ({
        [emailReducer.emailFeatureKey]: emailReducer.reducers,
        [puchaseReducer.purchaseFeatureKey]: puchaseReducer.reducers,
        [userReducer.userFeatureKey]: userReducer.reducers,
        [schoolReducer.schoolFeatureKey]: schoolReducer.reducers,
        [faqReducer.faqFeatureKey]: faqReducer.reducers,
        router: fromRouter.routerReducer,
    }),
});

export const ROOT_EFFECTS = [
    RoutingEffects,
    EmailEffects,
    AppEffects,
    PurchaseEffects,
    UserEffects,
    SchoolEffects,
    FaqEffects
];

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.groupEnd();

        return result;
    };
}


/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];

