<!-- ========== MAIN CONTENT ========== -->
<main id="content">
    <!-- Slider Banner Section -->
    <div class="js-slick-carousel u-slick" data-infinite="true" data-autoplay="true" data-fade="true" data-speed="5000"
        data-adaptive-height="true">
        <!-- Slide #1 -->
        <div class="js-slide">
            <div class="d-md-flex align-items-md-center height-100vh--lg gradient-overlay-half-dark-v2 bg-img-hero"
                style="background-image: url(../../assets/img/1920x1080/img31.jpg);">
                <div class="container text-center space-2 space-3--md">
                    <!-- Description -->
                    <div class="w-lg-80 mx-lg-auto mb-3">
                        <h1 class="display-3 font-size-48--md-down text-white" data-scs-animation-in="fadeInUp">
                            <object class="img-fluid box-shadow rounded" type="image/svg+xml"
                                data="../../assets/svg/logos/dead_header_logo.svg">
                                D.E.A.D.
                            </object>
                        </h1>
                    </div>
                    <div class="w-lg-50 mx-lg-auto mb-7">
                        <p class="lead text-white" data-scs-animation-in="fadeInUp" data-scs-animation-delay="200">
                            Comprehensive National Board Exam Testing Prep</p>
                    </div>
                    <div data-scs-animation-in="fadeInUp" data-scs-animation-delay="400">
                        <!-- Fancybox -->
                        <a class="js-fancybox u-media-player" href="javascript:;"
                            data-src="https://www.youtube.com/watch?v=LnpGIZu8EHw&t=83s" data-speed="700"
                            data-animate-in="zoomIn" data-animate-out="zoomOut" data-caption="The D.E.A.D. Experience">
                            <span class="u-media-player__icon u-media-player__icon--xl">
                                <fa-icon class="u-media-player__icon-inner" [icon]="faPlay"></fa-icon>
                            </span>
                            <span class="text-white pl-2" data-scs-animation-in="fadeInUp"
                                data-scs-animation-delay="200">See the experience</span>
                        </a>
                        <!-- End Fancybox -->
                    </div>
                    <!-- End Description -->
                </div>
            </div>
        </div>
        <!-- End Slide #1 -->

        <!-- Slide #2 -->
        <div class="js-slide">
            <div class="d-md-flex align-items-md-center height-100vh--lg gradient-overlay-half-dark-v2 bg-img-hero"
                style="background-image: url(../../assets/img/1920x1080/img2.jpg);">
                <div class="container text-center space-2 space-3--md">
                    <!-- Description -->
                    <div class="w-lg-80 mx-lg-auto mb-3">
                        <h1 class="display-3 font-size-48--md-down text-white header-text-shadow"
                            data-scs-animation-in="fadeInUp">
                            Access Your Student Portal
                        </h1>
                    </div>
                    <div class="w-lg-50 mx-lg-auto mb-7">
                        <p class="lead text-white" data-scs-animation-in="fadeInUp" data-scs-animation-delay="200">If
                            you have recently recieved an access code click below to get started!</p>
                    </div>
                    <div data-scs-animation-in="fadeInUp" data-scs-animation-delay="400">
                        <a class="btn btn-primary" [routerLink]="['/subscriptions/activate']">Click Here If You Have A
                            Code</a>
                    </div>
                    <!-- End Description -->
                </div>
            </div>
        </div>
        <!-- End Slide #2 -->
    </div>
    <!-- End Slider Banner Section -->

    <div class="container space-2 space-3--lg">
        <dead-subscription-types></dead-subscription-types>
    </div>

    <!-- Divider -->
    <div class="w-50 w-lg-35 mx-auto">
        <hr class="my-0">
    </div>
    <!-- End Divider -->

    <!-- Stats Section -->
    <div class="container space-2 space-3--lg">
        <!-- Title -->
        <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <h1 class="h3">What Are They and How Can They Help?</h1>
            <p>
                The biggest test of your life is coming up and there is so much to do. Studying for your boards is
                stressful. There is so much material to go over and it is impossible to know what may be on the test. If
                you are going to spend weeks studying for your boards, wouldn’t you like to know that you are studying
                the right information? D.E.A.D. can help.
            </p>
        </div>
        <!-- End Title -->

        <div class="row justify-content-md-center pb-9">
            <div class="col-6 col-md-5 col-lg-4">
                <!-- Stats -->
                <div class="text-center">
                    <span class="display-4 d-block text-dark mb-2 js-counter" data-comma-separated="true">
                        3500
                    </span>
                    <p class="lead">Subject specific questions</p>
                </div>
                <!-- End Stats -->
            </div>

            <div class="col-6 col-md-5 col-lg-4">
                <!-- Stats -->
                <div class="text-center">
                    <span class="display-4 d-block text-dark mb-2 js-counter" data-comma-separated="true">
                        16
                    </span>
                    <p class="lead">Subject Areas</p>
                </div>
                <!-- End Stats -->
            </div>
        </div>

        <!-- Features Section -->
        <div class="container position-relative z-index-2">
            <div class="bg-gray-100 shadow-lg rounded mt-n9">
                <div class="card-deck d-block d-lg-flex card-lg-gutters-1">
                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/consult-cyan-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h2 class="h6 text-dark mb-1">Funeral Arranging and Directing</h2>
                                    <p class="mb-0">
                                        Funeral Directing/Communications, Burial Customs, and Body Preparation
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/plan-brown-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h3 class="h6 text-dark mb-1">Funeral Service Marketing/Merchandising</h3>
                                    <p class="mb-0">Accounting, Management, Merchandising, and Casket Parts</p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/create-blue-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h4 class="h6 text-dark mb-1">Funeral Service Consoling</h4>
                                    <p class="mb-0">Psychology and Sociology</p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>
                </div>

                <div class="card-deck d-block d-lg-flex card-lg-gutters-1">
                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/consult-cyan-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h2 class="h6 text-dark mb-1">Legal and Regulatory Compliance</h2>
                                    <p class="mb-0">OSHA, FTC, Business Law, and Mortuary Law</p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/plan-brown-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h3 class="h6 text-dark mb-1">Cemetery and Crematory Operations</h3>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-2">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/create-blue-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h4 class="h6 text-dark mb-1">Embalming</h4>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>
                </div>

                <div class="card-deck d-block d-lg-flex card-lg-gutters-1">
                    <div class="card border-0 mb-2 mb-lg-0">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/develop-purple-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h4 class="h6 text-dark mb-1">Restorative Art</h4>
                                    <p class="mb-0"></p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-2 mb-lg-0">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2" src="../../assets/svg/components/rocket-red-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h4 class="h6 text-dark mb-1">Preparation for Disposition</h4>
                                    <p class="mb-0">Burial Customs and Body Preparation</p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>

                    <div class="card border-0 mb-0">
                        <!-- Listing -->
                        <div class="card-body p-4">
                            <div class="media">
                                <!-- <img class="max-width-9 mb-2"
                                    src="../../assets/svg/components/data-analysis-dark-icon.svg"
                                    alt="Image Description"> -->
                                <div class="media-body px-4">
                                    <h4 class="h6 text-dark mb-1">Funeral Service Sciences</h4>
                                    <p class="mb-0">
                                        Pathology, Anatomy, and Microbiology
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- End Listing -->
                    </div>
                </div>
            </div>
        </div>
        <!-- End Features Section -->
    </div>
    <!-- End Stats Section -->

    <!-- CTA -->
    <div class="gradient-overlay-half-primary-v1">
        <div class="bg-img-hero" style="background-image: url(../../assets/img/bg/bg2.png);">
            <div class="container text-center space-2">
                <!-- Title -->
                <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
                    <h2 class="h1 text-white">The Trusted Resource of America's Top Mortuary Schools</h2>
                    <p class="lead text-white">Contact us today about becoming a partner</p>
                </div>
                <!-- End Title -->

                <!-- Slick Carousel -->
                <div class="js-slick-carousel u-slick" data-autoplay="true" data-speed="5000" data-infinite="true"
                    data-slides-show="6" data-responsive='[{
                   "breakpoint": 1200,
                   "settings": {
                     "slidesToShow": 4
                   }
                 }, {
                   "breakpoint": 992,
                   "settings": {
                     "slidesToShow": 4
                   }
                 }, {
                   "breakpoint": 768,
                   "settings": {
                     "slidesToShow": 3
                   }
                 }, {
                   "breakpoint": 576,
                   "settings": {
                     "slidesToShow": 3
                   }
                 }, {
                   "breakpoint": 480,
                   "settings": {
                     "slidesToShow": 2
                   }
                 }]'>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/Delgado.png"
                            style="height: 45px; width: 175px;" alt="Delgado Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/canton.svg"
                            style="height: 45px; width: 175px;" alt="State University of New York at Canton">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/hudson_valley.png"
                            alt="Hudson Valley Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/nassau_community_college.png"
                            alt="Nassau Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/goodwin-college-logo.png"
                            style="height: 45px; width: 175px;" alt="Goodwin University">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/northampton_community_college.png"
                            style="height: 45px; width: 175px;" alt="Northampton Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/eastwick.png"
                            style="height: 45px; width: 175px;" alt="Eastwick">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/piedmont_college.png"
                            style="height: 45px; width: 175px;" alt="Piedmont College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/alamocolleges.jpg"
                            style="height: 45px; width: 175px;" alt="Alamo Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/fscj.png"
                            style="height: 45px; width: 175px;" alt="Florida State University Jacksonville">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/mt_hood_community_college.png"
                            style="height: 45px; width: 175px;" alt="Mt. Hood Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/mercer-university.png"
                            style="height: 45px; width: 175px;" alt="Mercer University">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/udc.png"
                            style="height: 55px; width: 175px;" alt="University of D.C.">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/chandler_gilbert.png"
                            style="height: 45px; width: 175px;" alt="Chandler-Gilbert Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/NorthwestMississippiLogo.png"
                            style="height: 45px; width: 175px;" alt="Northwest Mississippi Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/CIFS-logo.jpg"
                            style="height: 45px; width: 175px;" alt="Commonwealth Institute of Funeral Services">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/acc-logo-purple.svg"
                            style="height: 45px; width: 175px;" alt="Arapahoe Community College">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/logo-dallas.svg"
                            style="height: 45px; width: 175px;" alt="Dallas Institute College of Funeral Service">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/logo-guptonjones.svg"
                            style="height: 45px; width: 175px;" alt="Gupton-Jones College of Funeral Service">
                    </div>
                    <div class="js-slide">
                        <img class="u-clients" src="../../assets/svg/schools/logo-midamerica.svg"
                            style="height: 45px; width: 175px;" alt="Mid-America College of Funeral Service">
                    </div>
                </div>
                <!-- End Slick Carousel -->
            </div>
        </div>
    </div>
    <!-- End CTA -->

    <!-- About Section -->
    <div class="bg-gray-100">
        <div class="container space-2 space-3--lg">
            <!-- Title -->
            <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
                <span class="u-label u-label--purple mb-3">What is D.E.A.D.?</span>
                <h1 class="h3">D.E.A.D. stands for Death Education Assessment Drills.</h1>
                <p>
                    We offer comprehensive national board exam testing
                    preparation. D.E.A.D. 3.0 is our latest model and its enhanced questions are better than ever. Our
                    tool
                    features over 3,500 questions. These questions cover every major subject that may be tested on the
                    NBE.
                </p>
            </div>
            <!-- End Title -->

            <div class="row align-items-lg-center">
                <div class="col-md-6 order-md-2 mb-5 mb-md-0">
                    <div class="mb-4">
                        <!-- Title -->
                        <h2>How Does It Work?</h2>
                        <p>
                            Our tool is simple to use. Simply select the number of questions that you
                            want to
                            have in a study session and select which topic you’d like to study. This allows you to
                            create your own exams and focus on the areas that you feel ill-equipped for.
                        </p>
                        <p>
                            You can create as many practice exams as you would like. Our exams feature real
                            time scoring to help you pinpoint areas of improvement.
                        </p>
                        <p>
                            D.E.A.D. can even generate full-length exams. These comprehensive
                            evaluations
                            contain 150 questions randomly selected from every topic. Once finished, you can continue
                            studying the subjects you find difficult and recreate the exam as many times as you need.
                        </p>
                        <p>
                            Our tool features over 3,500 unique questions, and exams are randomly generated. Each exam
                            is unique and designed to thoroughly
                            test
                            your knowledge.
                        </p>
                        <p>
                            If your mortuary school has partnered with D.E.A.D., instructors can use our portal to
                            monitor
                            your progress.
                        </p>
                        <!-- End Title -->

                        <!-- Features List -->
                        <!-- <ul class="text-secondary">
                        <li class="py-1">It's important to stay detail oriented with every project we tackle.</li>
                        <li class="py-1">Staying focused allows us to turn every project we complete into something we
                            love.</li>
                    </ul> -->
                        <!-- End Features List -->

                        <hr class="my-5">

                        <!-- Review -->
                        <!-- <div class="media">
                            <img class="lazyload u-avatar rounded-circle mr-3" src="../../assets/img/100x100/img4.jpg"
                                alt="Image Description">
                            <div class="media-body">
                                <h4 class="h6 mb-0">Mark McManus</h4>
                                <p class="mb-0">"We are incredibly impressed with Dead and how well it supports its
                                    customers with
                                    amazing products and services. One simple subscription gives you access to all our
                                    tools, plus so much
                                    more."</p>
                            </div>
                        </div> -->
                        <!-- End Review -->
                    </div>
                </div>

                <div class="col-md-6 order-md-1">
                    <!-- Cubeportfolio -->
                    <div class="cbp" data-layout="mosaic" data-animation="quicksand" data-x-gap="15" data-y-gap="15"
                        data-load-more-selector="#cubeLoadMore" data-load-more-action="auto" data-load-items-amount="4"
                        data-media-queries='[
           {"width": 1500, "cols": 4},
           {"width": 1100, "cols": 4},
           {"width": 800, "cols": 3},
           {"width": 480, "cols": 2},
           {"width": 400, "cols": 2}
       ]'>
                        <!-- Item -->
                        <div class="cbp-item">
                            <div class="cbp-caption">
                                <img src="../../assets/img/380x227/img1.jpg" alt="Image Description">
                            </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div class="cbp-item">
                            <div class="cbp-caption">
                                <img src="../../assets/img/380x360/img1.jpg" alt="Image Description">
                            </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div class="cbp-item">
                            <div class="cbp-caption">
                                <img src="../../assets/img/380x360/img2.jpg" alt="Image Description">
                            </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div class="cbp-item">
                            <div class="cbp-caption">
                                <img src="../../assets/img/380x227/img2.jpg" alt="Image Description">
                            </div>
                        </div>
                        <!-- End Item -->
                    </div>
                    <!-- End Cubeportfolio -->
                </div>
            </div>
        </div>
    </div>
    <!-- End About Section -->

    <!-- Social Section -->
    <div class="container space-2 space-3--lg">
        <!-- Title -->
        <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <span class="u-label u-label--purple mb-3">Join the Discussion</span>
            <h2 class="h3">Connect with us on our Facebook Page</h2>
        </div>
        <!-- End Title -->

        <div class="mx-auto" style="width: 340px;">
            <div class="fb-page" data-href="https://www.facebook.com/DEADDrills/" data-tabs="timeline" data-width=""
                data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
                data-show-facepile="true">
                <blockquote cite="https://www.facebook.com/DEADDrills/" class="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/DEADDrills/">Death Education Assessment Drills</a>
                </blockquote>
            </div>
        </div>


    </div>
    <!-- End Works Section -->
</main>
<!-- ========== END MAIN CONTENT ========== -->
