import { createAction, props } from '@ngrx/store';
import { VoucherCode } from '../../core/models/voucher-code.model';

export const loadVoucherCodes = createAction('[Voucher Codes] Load Voucher Codes');

export const reloadVoucherCodes = createAction('[Voucher Codes] Reload Voucher Codes');

export const loadVoucherCodesSuccess = createAction(
    '[Voucher Codes] Load Voucher Codes Success',
    props<{ voucherCodes: VoucherCode[] }>()
);

export const loadVoucherCodesFailure = createAction(
    '[Voucher Codes] Load Voucher Codes Failure',
    props<{ error: any }>()
);

export const getRandomVoucherCodes = createAction(
    '[Voucher Codes] Get Random Voucher Codes',
    props<{ numOfCodes: number, codeLength: number }>()
);

export const getRandomVoucherCodesSuccess = createAction(
    '[Voucher Codes] Get Random Voucher Codes Success',
    props<{ randomVoucherCodes: string[] }>()
);

export const getRandomVoucherCodesFailure = createAction(
    '[Voucher Codes] Get Random Voucher Codes Failure',
    props<{ error: any }>()
);

export const createVoucherCodes = createAction(
    '[Voucher Codes] Create Voucher Codes',
    props<{ codes: VoucherCode[], issueToEmail: string, redirectUrl: string }>()
);

export const editVoucherCode = createAction(
    '[Voucher Codes] Edit Voucher Code',
    props<{ voucherCode: VoucherCode, redirectUrl: string }>()
);

export const deleteVoucherCodes = createAction(
    '[Voucher Codes] Delete Voucher Codes',
    props<{ voucherCodeIds: string[] }>()
);

export const CreateVoucherCodesComplete = createAction(
    '[Voucher Codes] Create Voucher Codes Complete',
    props<{ redirectUrl: string }>()
);

export const addEditDeleteVoucherCodesComplete = createAction(
    '[Voucher Codes] Add/Edit/Delete Voucher Codes Complete',
    props<{ redirectUrl: string }>()
);

export const addEditDeleteVoucherCodesFailure = createAction(
    '[Voucher Codes] Add/Edit/Delete Voucher Codes Failure',
    props<{ error: any }>()
);
