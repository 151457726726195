import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import {
    addEditDeleteEmailTemplateComplete,
    addEditDeleteEmailTemplateFailure,
    addEmailTemplate, deleteEmailTemplate,
    editEmailTemplate, loadEmailTemplates,
    loadEmailTemplatesFailure,
    loadEmailTemplatesSuccess,
    reloadEmailTemplates,
    sendBulkEmailRequest,
    sendBulkEmailRequestComplete,
    sendBulkEmailRequestFailure,
    sendContactUsRequest,
    sendContactUsRequestComplete,
    sendContactUsRequestFailure
} from '../actions/email.actions';
import { EmailService } from '../core/services/email.service';
import { ToastService } from '../core/services/toast.service';



@Injectable()
export class EmailEffects {

    constructor(
        private actions$: Actions,
        private toastService: ToastService,
        private router: Router,
        private emailService: EmailService,
        private spinner: SpinnerVisibilityService,
    ) { }

    loadEmailTemplates$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadEmailTemplates),
                exhaustMap(() =>
                    this.emailService.getEmailTemplates()
                        .pipe(
                            map((emailTemplates) => {
                                return loadEmailTemplatesSuccess({ emailTemplates });
                            }),
                            catchError((error) => {
                                return of(loadEmailTemplatesFailure({ error }));
                            })
                        )
                )
            )
    );

    reloadEmailTemplates$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(reloadEmailTemplates),
                exhaustMap(() =>
                    this.emailService.deleteTemplateCache()
                        .pipe(
                            mergeMap(() => this.emailService.getEmailTemplates()),
                            map((emailTemplates) => {
                                return loadEmailTemplatesSuccess({ emailTemplates });
                            }),
                            catchError((error) => {
                                return of(loadEmailTemplatesFailure({ error }));
                            })
                        )
                )
            )
    );

    addEmailTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addEmailTemplate),
                exhaustMap(({ emailTemplate, redirectUrl }) =>
                    this.emailService.addEmailTemplate(emailTemplate)
                        .pipe(
                            map(() => {
                                return addEditDeleteEmailTemplateComplete({ redirectUrl: redirectUrl });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Adding Email Template', 'Add Email Template Failed!');
                                return of(addEditDeleteEmailTemplateFailure({ error }));
                            })
                        )
                )
            )
    );

    editEmailTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editEmailTemplate),
                exhaustMap(data =>
                    this.emailService.editEmailTemplate(data.emailTemplate)
                        .pipe(
                            map(() => {
                                return addEditDeleteEmailTemplateComplete({ redirectUrl: data.redirectUrl });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Editing Email Template', 'Edit Email Template Failed!');
                                return of(addEditDeleteEmailTemplateFailure({ error }));
                            })
                        )
                )
            )
    );

    deleteEmailTemplate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteEmailTemplate),
                map(action => action.emailTemplateId),
                exhaustMap(emailTemplateId =>
                    this.emailService.deleteEmailTemplate(emailTemplateId)
                        .pipe(
                            map(() => {
                                return addEditDeleteEmailTemplateComplete({ redirectUrl: '' });
                            }),
                            catchError((error) => {
                                this.toastService.error('An Error Occured When Deleting Email Template', 'Delete Email Template Failed!');
                                return of(addEditDeleteEmailTemplateFailure({ error }));
                            })
                        )
                )
            )
    );

    addEditDeleteEmailTemplateComplete$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(addEditDeleteEmailTemplateComplete),
                map(action => {
                    if (action.redirectUrl) {
                        this.router.navigate([action.redirectUrl]);
                    }
                    return reloadEmailTemplates();
                })
            )
    );

    sendBulkEmailRequest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(sendBulkEmailRequest),
                exhaustMap(data =>
                    this.emailService.sendBulkEmail(data.email)
                        .pipe(
                            map(() => {
                                this.toastService.success('Thank You, Message Sent!');
                                return sendBulkEmailRequestComplete();
                            }),
                            catchError((error) => {
                                this.toastService.error(`Sorry we could not send your message at this time.`, 'Email Failed To Send!');
                                return of(sendBulkEmailRequestFailure({ error }));
                            })
                        )
                )
            )
    );

    sendContactUsRequest$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(sendContactUsRequest),
                exhaustMap(data => {
                    this.spinner.show();
                    return this.emailService.sumbitContactRequest(data.contactUsRequest)
                        .pipe(
                            map(() => {
                                this.spinner.hide();
                                this.toastService.success('We will get back to you shortly. Thank You, Message Sent!');
                                return sendContactUsRequestComplete();
                            }),
                            catchError((error) => {
                                this.spinner.hide();
                                this.toastService.error(`Sorry we could not send your message at this time.
                                Please feel free to contact us by email. Error Send Message`);
                                return of(sendContactUsRequestFailure({ error }));
                            })
                        );
                })
            )
    );

}
