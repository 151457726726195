import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetCatNamePipe } from '../../core/pipes/get-cat-name.pipe';
import { GetSchoolNamePipe } from '../../core/pipes/get-school-name.pipe';
import { PhonePipe } from '../../core/pipes/phone.pipe';
import { SafeUrlPipe } from '../../core/pipes/safe-url.pipe';
import { AbsoluteNumberPipe } from './absolute-number.pipe';
import { DotDotDotPipe } from './dotdotdot.pipe';
import { SafeHtmlPipe } from './safe-html';
import { StripHtmlPipe } from './strip-html-pipe';



@NgModule({
    declarations: [
        GetCatNamePipe,
        PhonePipe,
        GetSchoolNamePipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        DotDotDotPipe,
        StripHtmlPipe,
        AbsoluteNumberPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GetCatNamePipe,
        PhonePipe,
        GetSchoolNamePipe,
        SafeUrlPipe,
        SafeHtmlPipe,
        DotDotDotPipe,
        StripHtmlPipe,
        AbsoluteNumberPipe
    ]
})
export class PipesModule { }
