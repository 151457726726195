export const environment = {
    production: false,
    pwaLocal: false,
    /** api enpdoing information */
        INSTRUCTOR_API_BASE: `https://deaddevapi.azure-api.net/instructor/api/`,
        STUDENT_API_BASE: `https://deaddevapi.azure-api.net/student/api/`,
        ADMIN_API_BASE:
            'https://deaddevapi.azure-api.net/admin/api/',
        GENERAL_API_BASE: 'https://deaddevapi.azure-api.net/general/api/',
        /** stripe information */
        STRIPE_PUBLIC_KEY: 'pk_test_nLWA8kwsQGYW5xi6dB1k40Db00EnCpBmpu',
        baseUrl: 'https://deaddev.z13.web.core.windows.net',
        apiSubscriptionKey: '4f847f890979420196e3289d1f2d94de',
        GAME_SOCKET_URL: 'https://dead-game-serv-staging.azurewebsites.net'
    };
